/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoggingProvider } from '@compass/logging';

export class NoLogProvider implements LoggingProvider {
  readonly name: string;

  constructor(name: string) {
    this.name = name ?? 'N/A';
  }

  /**
   * Checks whether error object has message property and stack property
   * @param error Object to check
   * @protected
   */
  protected isCommonError(
    error: unknown,
  ): error is { message: string; stack: string } {
    if (error === undefined || error === null) return false;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return error.message !== undefined && error.stack !== undefined;
  }

  debug(...args: unknown[]): void {
    // Do nothing
  }

  error(...args: unknown[]): void {
    // Do nothing
  }

  info(...args: unknown[]): void {
    // Do nothing
  }

  warn(...args: unknown[]): void {
    // Do nothing
  }
}
