/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, HostBinding, inject, Input } from '@angular/core';
import { ReportComponent } from '../models/components/report-component';
import {
  COMPONENT_CLASS_LIST,
  COMPONENT_TEMPLATE_ATTR,
} from '../models/components/global-attributes';
import { ReportService } from '../services/report.service';
import { Measure } from '../models/report/measure';
import { ReportData } from '../models/report/report-data';
import { ScoringBucket } from '../models/report/scoring-bucket';
import { Score } from '../models/report/score';
import { Lazy } from '@compass/helpers';
import { MeasureService } from '../services/measure.service';
import { Logger } from '@compass/logging';

/**
 * Base component for all public report components. That is any component
 * that is available for content creators to use is defined as public.
 * These components should only be instantiated by {@link ComponentRendererComponent}.
 * Do not rely on any inputs/properties being set during instantiation.
 * Instead, if you need to modify those, you should use any of the Angular
 * life cycle hooks such as NgOnInit.
 */
@Component({
  template: '',
})
export class PublicReportComponent {
  private _measureId?: string;
  private _measure = new Lazy(
    () => this.measureService.getMeasureById(this.measureId)!,
  );
  private _scoringBuckets = new Lazy(() =>
    this.reportData.scoring.scoringBuckets.filter(
      sb => sb.measureId === this.measureId,
    ),
  );
  private _target = new Lazy(
    () =>
      this.reportData.scoring.targets.find(
        t => t.measureId === this.measureId,
      )!,
  );
  private _score = new Lazy(
    () =>
      this.reportData.completed.scores.find(
        s => s.measureId === this.measureId,
      )!,
  );

  protected readonly reportService = inject(ReportService);
  protected readonly measureService = inject(MeasureService);
  protected readonly logger = inject(Logger);

  @HostBinding('class')
  @Input(COMPONENT_CLASS_LIST)
  class?: string;

  @Input()
  component!: ReportComponent;

  @Input(COMPONENT_TEMPLATE_ATTR)
  template: string = '';

  @Input()
  protected set measureId(value: string) {
    if (value === this._measureId) return;
    if (!this.measureService.hasMeasure(value)) return;

    this._measureId = value;
    this._measure.clear();
  }

  protected get measureId(): string {
    return this._measureId ?? this.component.measureScope;
  }

  protected get measure(): Measure {
    return this._measure.value;
  }

  protected get reportData(): ReportData {
    return this.reportService.reportData!;
  }

  protected get score(): Score {
    return this._score.value;
  }

  protected get scoringBuckets(): ScoringBucket[] {
    return this._scoringBuckets.value;
  }

  protected get target(): ScoringBucket {
    return this._target.value;
  }
}
