import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ScaleService } from './scale.service';

/**
 * Directive for setting a scaling target within {@link ScaleService}
 */
@Directive({
  selector: '[cpScaleTarget]',
})
export class ScaleTargetDirective implements OnInit, OnDestroy {
  private readonly _element?: HTMLElement;

  constructor(
    private readonly _scaleService: ScaleService,
    _elementRef: ElementRef,
  ) {
    if (!(_elementRef.nativeElement instanceof HTMLElement)) return;

    this._element = _elementRef.nativeElement;
  }

  ngOnInit(): void {
    if (!this._element) return;

    this._scaleService.addTarget(this._element);
  }

  ngOnDestroy(): void {
    if (!this._element) return;

    this._scaleService.removeTarget(this._element);
  }
}
