import { Component, OnDestroy } from '@angular/core';
import { appConfig } from '../../../environment/environment';
import { ReportData } from '../../shared/models/report/report-data';
import { ReportService } from '../../shared/services/report.service';
import { Logger } from '@compass/logging';

interface MessageData {
  debug: boolean;
  data?: ReportData;
  reportId?: string;
}

const defaultMessageData: MessageData = {
  debug: false,
};

@Component({
  selector: 'cp-rp-embedded',
  templateUrl: './embedded.component.html',
})
export class EmbeddedComponent implements OnDestroy {
  private _messageData?: MessageData;

  protected get messageData(): MessageData | undefined {
    return this._messageData;
  }

  protected set messageData(value: MessageData | undefined) {
    this._messageData = value;

    this.reportService.reportData = value?.data;
  }

  constructor(
    protected readonly reportService: ReportService,
    private readonly _logger: Logger,
  ) {
    window.addEventListener('message', this.onMessageReceived.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.onMessageReceived);
  }

  protected getReportTemplateById(id?: string): string {
    const report =
      this.messageData?.data?.completed?.reports?.find(
        r => r.reportId === id,
      ) ?? this.messageData?.data?.completed?.reports?.find(r => r.isDefault);

    return report?.body ?? '';
  }

  private onMessageReceived(event: MessageEvent): void {
    if (
      !event.data ||
      event.origin === location.origin ||
      this.isFrameSizerPayload(event.data)
    ) {
      return;
    }

    if (!this.isDomainAllowed(event.origin)) {
      this._logger.warn(
        `Report frame received message from blocked origin: ${event.origin}. The message will be ignored.`,
      );
      return;
    }

    if (!this.isMessageData(event.data)) {
      this._logger.warn(
        'Report frame received unexpected data. The message will be ignored.',
      );
      this._logger.debug(event.data);
      return;
    }

    this.messageData = {
      ...defaultMessageData,
      ...event.data,
    };
  }

  private isMessageData(reportData: MessageData): reportData is MessageData {
    return typeof reportData === 'object';
  }

  private isDomainAllowed(origin: string): boolean {
    if (!appConfig.allowedMessageOrigins) return false;

    for (const urlRegex of appConfig.allowedMessageOrigins) {
      if (urlRegex === '*' || new RegExp(urlRegex).test(origin)) return true;
    }

    return false;
  }

  private isFrameSizerPayload(payload?: unknown): boolean {
    return typeof payload === 'string' && payload.startsWith('[iFrameSizer]');
  }
}
