import { Injectable } from '@angular/core';
import { BrowserInfo } from './browser-info';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { ScreenSize } from './screen-size';
import { DeviceInfo } from './device-info';
import { UAParser } from 'ua-parser-js';

/**
 * Provides information about the device running the application
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  readonly browser: BrowserInfo;
  readonly device: DeviceInfo;

  readonly screenSize$ = fromEvent(window, 'resize').pipe(
    debounceTime(250),
    map(() => {
      return {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      } as ScreenSize;
    }),
    distinctUntilChanged(),
  );

  constructor() {
    const parser = new UAParser();

    this.browser = this.getBrowserInfo(parser);
    this.device = this.getDeviceInfo(parser);
  }

  private getBrowserInfo(parser: UAParser): BrowserInfo {
    const browser = parser.getBrowser();

    return {
      name: browser.name ?? '',
      version: browser.version ?? '',
    };
  }

  private getDeviceInfo(parser: UAParser): DeviceInfo {
    const device = parser.getDevice();
    const os = parser.getOS();

    return {
      os: os.name ?? '',
      version: os.version ?? '',
      isMobile: device.type === 'mobile',
      isDesktop:
        device.type === undefined ||
        !['wearable', 'mobile'].includes(device.type),
      isTablet: device.type === 'tablet',
      isMac: device.vendor === 'Apple' && device.model === 'Macintosh',
    };
  }
}
