import { ModuleWithProviders, NgModule } from '@angular/core';
import { Logger, LoggingProvider, LogLevel } from './index';
import { toArray } from '@compass/helpers';

@NgModule()
export class LoggingModule {
  public static forRoot(
    providers: LoggingProvider[] | LoggingProvider,
    minLogLevel: LogLevel = 'debug',
  ): ModuleWithProviders<LoggingModule> {
    return {
      ngModule: LoggingModule,
      providers: [
        {
          provide: Logger,
          useValue: new Logger(toArray(providers), minLogLevel),
        },
      ],
    };
  }
}
