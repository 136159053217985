import { Logger } from '@compass/logging';
import {
  ApplicationEnvironment,
  BrowserInfo,
  DeviceInfo,
} from '@compass/environment';

/**
 * Logs message with initialization details
 * @param appEnvironment
 */
export function logInitMessage(appEnvironment: ApplicationEnvironment): void {
  Logger.instance.info(
    `${appEnvironment.name} v${appEnvironment.version} started with ${appEnvironment.environment} environment.`,
  );
}

/**
 * Logs message with device details
 * @param browser Information about the browser
 * @param device Device information
 */
export function logDeviceMessage(
  browser: BrowserInfo,
  device: DeviceInfo,
): void {
  const logMessage = `Detected ${browser.name} (${browser.version}) on ${device.os}.`;

  Logger.instance.debug(logMessage);
}
