import { Injectable, Injector } from '@angular/core';
import { ValidationErrors, Validator } from 'fluentvalidation-ts';
import { Activator } from '@compass/helpers';

@Injectable()
export class ValidatorService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly _validators = new Map<string, Validator<any>>();

  constructor(private readonly _injector: Injector) {}

  /**
   * Creates an instance of object validator
   * @param validatorClass Class of the validator to create
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public createValidator<TValidator extends Validator<any>>(
    validatorClass: new (...args: never[]) => TValidator,
  ): TValidator {
    return Activator.createInstance(validatorClass, this._injector);
  }

  /**
   * Validates an object with given validator
   * @param validatorClass Class of the validator to use
   * @param value Value to validate
   */
  public validate<TValidator extends Validator<TType>, TType>(
    validatorClass: new (...args: never[]) => TValidator,
    value: TType,
  ): ValidationErrors<TType> {
    let validatorInstance = this._validators.get(
      validatorClass.name,
    ) as TValidator;

    if (!validatorInstance) {
      validatorInstance = Activator.createInstance(
        validatorClass,
        this._injector,
      );
      this._validators.set(validatorClass.name, validatorInstance);
    }

    return validatorInstance.validate(value);
  }
}
