<div class="range bg-secondary d-inline-block position-relative w-100">
  <div
    *ngIf="showProgress"
    [style.width]="progress * 100 + '%'"
    class="score-bar bg-primary rounded position-absolute"></div>
  <div
    *ngIf="targetFloor !== undefined && targetCeiling !== undefined"
    [ngbTooltip]="
      'Ideal range is from ' +
      (targetFloorDisplayValue ?? targetFloor) +
      ' to ' +
      (targetCeilingDisplayValue ?? targetCeiling)
    "
    [style.--targetWidth]="targetCeiling - targetFloor"
    [style.--targetOffset]="targetFloor"
    class="target-bar bg-warning position-absolute"></div>
  <div
    [style.left]="progress * 100 + '%'"
    [ngbTooltip]="displayValue | toString"
    [disableTooltip]="!showValue"
    class="score-circle text-white translate-middle-x position-absolute rounded-circle fw-light d-flex justify-content-center align-items-center bg-primary">
    <ng-container *ngIf="showValue">
      {{ formattedDisplayValue ?? progress }}
    </ng-container>
  </div>
</div>

<div
  class="tails mt-2 px-1 d-flex justify-content-between"
  *ngIf="tailStart || tailEnd">
  <span>{{ tailStart }}</span>
  <span>{{ tailEnd }}</span>
</div>
