/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';
import { ReportService } from './report.service';
import { Participant } from '../models/report/participant';
import { ScoringBucket } from '../models/report/scoring-bucket';
import { Measure } from '../models/report/measure';
import { Score } from '../models/report/score';
import { ReportData } from '../models/report/report-data';
import { ObjectIndex } from '@compass/types';
import { handlebarsExtensions } from '../constants/handlebars-extensions';
import { Logger } from '@compass/logging';

declare const Handlebars: never;

interface TemplateData {
  scoringBucket: ScoringBucket;
  measure: Measure;
  score: Score;
  tags: ObjectIndex<string[]>;
  participant: Participant;
  /** Shorthand for participant.name **/
  name: string;
  assessment: {
    name: string;
  };
}

interface TemplateDataWithChildren extends TemplateData {
  children: ObjectIndex<TemplateDataWithChildren>;
}

interface RootTemplateData extends TemplateDataWithChildren {
  participant: Participant;
  parent?: TemplateData;
}

/**
 * Service for providing data and rendering component tamplate
 */
@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private readonly _dataCache = new Map<string, RootTemplateData>();

  constructor(
    private readonly _reportService: ReportService,
    private readonly _logger: Logger,
  ) {
    // Register any extension
    for (const ext of handlebarsExtensions) {
      // @ts-ignore
      Handlebars.registerHelper(ext.key, ext.helper);
    }
  }

  render(template: string, measureId?: string): string {
    if (!template || !this._reportService.hasReport) return '';

    measureId ??= 'root';

    // @ts-ignore
    const compiledTemplate = Handlebars.compile(template);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = this.getTemplateData(
      measureId,
      this._reportService.reportData!,
    );

    try {
      return compiledTemplate(data);
    } catch (error) {
      this._logger.warn(`Failed to render template: '${template}'`);
      this._logger.error(error);
      return '<span class="bg-danger text-white py-1 px-2 rounded fst-italic">Failed to render template!</span>';
    }
  }

  private getTemplateData(
    measureId: string,
    data: ReportData,
  ): RootTemplateData {
    if (this._dataCache.has(measureId)) {
      return this._dataCache.get(measureId) as RootTemplateData;
    }

    const templateData: RootTemplateData = {
      ...this.getItemsForMeasureWithChildren(measureId, data),
      participant: data.participant,
      parent: this.getParent(measureId, data),
    };

    this._dataCache.set(measureId, templateData);

    // Log it in debug for the dev to see what's going on...
    this._logger.debug(
      `Template data was generated for measure ID [${measureId}]`,
    );
    this._logger.debug(templateData);

    return templateData;
  }

  private getItemsForMeasure(
    measureId: string,
    data: ReportData,
  ): TemplateData {
    const score = data.completed.scores.find(s => s.measureId === measureId)!;
    const scoringBucket = data.scoring.scoringBuckets.find(
      sb => sb.scoringBucketId === score.scoringBucketId,
    )!;
    const measure = data.assessment.measures.find(
      m => m.measureId === measureId,
    )!;
    const tags = this.getTags(measureId, data);

    return {
      score,
      scoringBucket,
      tags,
      measure,
      participant: data.participant,
      name: data.participant.name,
      assessment: {
        name: data.assessment.name,
      },
    };
  }

  private getItemsForMeasureWithChildren(
    measureId: string,
    data: ReportData,
  ): TemplateDataWithChildren {
    return {
      ...this.getItemsForMeasure(measureId, data),
      children: this.getChildren(measureId, data),
    };
  }

  private getTags(measureId: string, data: ReportData): ObjectIndex<string[]> {
    const tags: ObjectIndex<string[]> = {};

    for (const tag in data.completed.tags) {
      if (!data.completed.tags[tag][measureId]) continue;

      tags[tag] = data.completed.tags[tag][measureId].map(t => t.values).flat();
    }

    return tags;
  }

  private getChildren(
    measureId: string,
    data: ReportData,
  ): ObjectIndex<TemplateDataWithChildren> {
    const children: TemplateDataWithChildren[] = [];

    for (const child of data.assessment.measures.filter(
      m => m.parentMeasureId === measureId,
    )) {
      children.push(this.getItemsForMeasureWithChildren(child.measureId, data));
    }

    return children.reduce((a, v) => ({ ...a, [v.measure.measureId]: v }), {});
  }

  private getParent(
    measureId: string,
    data: ReportData,
  ): TemplateData | undefined {
    const parentMeasureId = data.assessment.measures.find(
      m => m.measureId === measureId,
    )?.parentMeasureId;

    return parentMeasureId
      ? this.getItemsForMeasure(parentMeasureId, data)
      : undefined;
  }
}
