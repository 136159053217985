import { Component, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'cp-rp-template',
  templateUrl: './template.component.html',
})
export class TemplateComponent extends PublicReportComponent implements OnInit {
  private _renderedTemplate: string = '';

  get renderedTemplate(): string {
    return this._renderedTemplate;
  }

  constructor(private readonly _templateService: TemplateService) {
    super();
  }

  ngOnInit(): void {
    const prerenderedTemplate = this._templateService.render(
      this.template,
      this.measure.measureId,
    );
    this._renderedTemplate = this._templateService.render(
      prerenderedTemplate,
      this.measure.measureId,
    );
  }
}
