import { NoLogProvider } from './no-log-provider';
import { LogLevel } from '../options/log-level';

/**
 * Logging provider that logs to console
 */
export class ConsoleLoggingProvider extends NoLogProvider {
  constructor(name: string = 'Console') {
    super(name);
  }

  override error(...args: unknown[]): void {
    for (const item of args) {
      this.printMessageInStyle('error', item);
    }
  }

  override info(...args: unknown[]): void {
    for (const item of args) {
      this.printMessageInStyle('info', item);
    }
  }

  override debug(...args: unknown[]): void {
    for (const item of args) {
      this.printMessageInStyle('debug', item);
    }
  }

  override warn(...args: unknown[]): void {
    for (const item of args) {
      this.printMessageInStyle('warn', item);
    }
  }

  private printMessageInStyle(logLevel: LogLevel, item: unknown): void {
    const badges = `%c${this.name}%c${logLevel}`;
    const nameBadgeStyle = this.getBadgeStyle('lightgray');

    switch (logLevel) {
      case 'debug':
        console.debug(
          badges,
          nameBadgeStyle,
          this.getBadgeStyle('darkslategray', 'white'),
          item,
        );
        break;
      case 'info':
        console.info(
          badges,
          nameBadgeStyle,
          this.getBadgeStyle('cornflowerblue'),
          item,
        );
        break;
      case 'error':
        console.error(
          badges,
          nameBadgeStyle,
          this.getBadgeStyle('#E97A74'),
          item,
        );
        break;
      case 'warn':
        console.warn(
          badges,
          nameBadgeStyle,
          this.getBadgeStyle('#E4A534'),
          item,
        );
        break;
    }
  }

  private getBadgeStyle(
    backgroundColor: string,
    textColor: string = 'black',
  ): string {
    return `color: ${textColor}; background-color: ${backgroundColor}; padding: 2px 5px; border-radius: 5px;margin-right: 5px; text-transform: lowercase;`;
  }
}
