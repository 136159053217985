import {
  DefaultHttpRequestOptions,
  HttpRequestOptions,
} from './http-request-options';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom, Observable, of } from 'rxjs';
import {
  SuccessApiResponse,
  ErrorApiResponse,
  isCustomHttpErrorResponse,
} from '../responses';
import { isJsException, JsException, ObjectIndex } from '@compass/types';
import { HttpRequestMethod } from './http-request-method';

export class HttpRequest<TResult = unknown> {
  private readonly _datePipe: DatePipe = new DatePipe('en-US');
  private readonly _options: HttpRequestOptions;

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _route: string,
    options?: Partial<HttpRequestOptions>,
  ) {
    this._options = options
      ? {
          ...DefaultHttpRequestOptions,
          ...options,
        }
      : DefaultHttpRequestOptions;
  }

  /**
   * Sends the request to the server
   */
  send$(): Observable<SuccessApiResponse<TResult> | ErrorApiResponse> {
    try {
      return this.sendJson().pipe(
        catchError(httpError => {
          if (isCustomHttpErrorResponse(httpError.error)) {
            return of(httpError.error);
          } else {
            return of(this.getEmptyErrorResponse(httpError.message));
          }
        }),
      );
    } catch (error) {
      return of(
        isJsException(error)
          ? this.getExceptionErrorResponse(error)
          : this.getEmptyErrorResponse(),
      );
    }
  }

  /**
   * Sends the request to the server
   */
  send(): Promise<SuccessApiResponse<TResult> | ErrorApiResponse> {
    return firstValueFrom(this.send$());
  }

  private sendJson(): Observable<SuccessApiResponse<TResult>> {
    switch (this._options.method) {
      case HttpRequestMethod.Get:
        return this._httpClient.get<SuccessApiResponse<TResult>>(this._route);
      case HttpRequestMethod.Put:
        return this._httpClient.put<SuccessApiResponse<TResult>>(
          this._route,
          this._options.body,
        );
      case HttpRequestMethod.Post:
        return this._httpClient.post<SuccessApiResponse<TResult>>(
          this._route,
          this._options.body,
        );
    }
  }

  private getEmptyErrorResponse(message?: string): ErrorApiResponse {
    return {
      isSuccess: false,
      path: '',
      status: 0,
      timestamp: 0,
      errors: [{ message: message ?? 'An unknown error has occurred.' }],
    };
  }

  private getExceptionErrorResponse(exception: JsException): ErrorApiResponse {
    return {
      isSuccess: false,
      path: '',
      status: 0,
      timestamp: 0,
      errors: [
        {
          message: exception.message,
          exception: {
            message: exception.message,
            stackTrace: exception.stack,
          },
        },
      ],
    };
  }

  /**
   * Converts values to query string
   * @param values
   * @protected
   */
  private getQueryString(...values: ObjectIndex[]): string {
    const parts = [];
    for (const obj of values) {
      if (obj === undefined || obj === null) continue;

      for (const property of Object.getOwnPropertyNames(obj)) {
        const value = obj[property];
        // Leave this as is. Need explicit check for null & undefined because some of the properties might be bool
        if (value === null || value === undefined) continue;

        parts.push(
          this.createQueryStringPair(
            property,
            this.getFormattedQueryStringValue(value),
          ),
        );
      }
    }

    return parts.length > 0 ? parts.join('&') : '';
  }

  private createQueryStringPair(key: string, value: string): string {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  private getFormattedQueryStringValue(value: unknown): string {
    if (value instanceof Date) {
      return this.formatDate(value);
    }

    return value?.toString() ?? '';
  }

  private formatDate(date: Date): string {
    return this._datePipe.transform(date, 'YYYY-MM-dd') ?? '';
  }
}
