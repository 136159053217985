import { Size, sizeIndex } from '@compass/types';

/**
 * Breakpoint definition class
 */
export interface BreakpointDef {
  /**
   * Size that this breakpoint relates to
   */
  size: Size;

  /**
   * Media query for the size
   */
  mediaQuery: string;

  /**
   * Whether the breakpoint is a size
   * @param size Size to check
   */
  is: (size: Size) => boolean;

  /**
   * Whether the breakpoint is larger than a size
   * @param size Size to check
   */
  largerThan: (size: Size) => boolean;

  /**
   * Whether the breakpoint is smaller than a size
   * @param size Size to check
   */
  smallerThan: (size: Size) => boolean;
}

// @sonar-ignore-start

export const sizeBreakpoints: BreakpointDef[] = [
  {
    size: 'xsmall',
    mediaQuery: '(max-width: 574px)',
    is: isFn('xsmall'),
    smallerThan: smallerThanFn('xsmall'),
    largerThan: largerThanFn('xsmall'),
  },
  {
    size: 'small',
    mediaQuery: '(min-width: 575px) and (max-width: 766px)',
    is: isFn('small'),
    smallerThan: smallerThanFn('small'),
    largerThan: largerThanFn('small'),
  },
  {
    size: 'medium',
    mediaQuery: '(min-width: 767px) and (max-width: 990px)',
    is: isFn('medium'),
    smallerThan: smallerThanFn('medium'),
    largerThan: largerThanFn('medium'),
  },
  {
    size: 'large',
    mediaQuery: '(min-width: 991px) and (max-width: 1198px)',
    is: isFn('large'),
    smallerThan: smallerThanFn('large'),
    largerThan: largerThanFn('large'),
  },
  {
    size: 'xlarge',
    mediaQuery: '(min-width: 1199px) and (max-width: 1398px)',
    is: isFn('xlarge'),
    smallerThan: smallerThanFn('xlarge'),
    largerThan: largerThanFn('xlarge'),
  },
  {
    size: 'xxlarge',
    mediaQuery: '(min-width: 1399px)',
    is: isFn('xxlarge'),
    smallerThan: smallerThanFn('xxlarge'),
    largerThan: largerThanFn('xxlarge'),
  },
];

// @sonar-ignore-end

function isFn(current: Size): (size: Size) => boolean {
  return (size: Size) => size === current;
}

function smallerThanFn(current: Size): (size: Size) => boolean {
  return (size: Size) => sizeIndex(current) - sizeIndex(size) < 0;
}

function largerThanFn(current: Size): (size: Size) => boolean {
  return (size: Size) => sizeIndex(current) - sizeIndex(size) > 0;
}
