import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Score } from '../../models/report/score';
import { ScoringBucket } from '../../models/report/scoring-bucket';
import {
  ChartConfig,
  ChartConfigProvider,
} from '../../services/chart-config-provider.service';
import { MeasureService } from '../../services/measure.service';

@Component({
  selector: 'cp-rp-measure-score-chart',
  templateUrl: './measure-score-chart.component.html',
  styleUrls: ['./measure-score-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasureScoreChartComponent implements OnInit, OnChanges {
  private _target?: ScoringBucket;
  private _chartConfig?: ChartConfig;
  private _score?: Score;

  @Input({ required: true })
  measureId: string = '';

  @Input()
  showRangeTicks: boolean = true;

  @Input()
  showRangeValues: boolean = true;

  @Input()
  showValue: boolean = true;

  @Input()
  tailStart?: string;

  @Input()
  tailEnd?: string;

  protected get target(): ScoringBucket | undefined {
    return this._target;
  }

  protected get chartConfig(): ChartConfig | undefined {
    return this._chartConfig;
  }

  protected get score(): Score | undefined {
    return this._score;
  }

  constructor(
    private readonly _chartConfigProvider: ChartConfigProvider,
    private readonly _measureService: MeasureService,
  ) {}

  ngOnInit(): void {
    this.recomputeComponentValues();
  }

  ngOnChanges(): void {
    this.recomputeComponentValues();
  }

  private recomputeComponentValues(): void {
    this._target = this._measureService.getTargetForMeasure(this.measureId);
    this._score = this._measureService.getScoreForMeasure(this.measureId);

    if (!this.score) return;

    this._chartConfig = this._chartConfigProvider.getConfig(this.score);
  }
}
