import { Injector } from '@angular/core';
import { Logger } from '@compass/logging';

/**
 * Utility to create instance of classes that need their
 * dependencies resolved from the DI container
 */
export class Activator {
  /**
   * Creates an instance of a class
   * @param instanceClass Class to create instance of
   * @param injector Injector to resolve dependencies from
   */
  public static createInstance<TInstance>(
    instanceClass: new (...args: never[]) => TInstance,
    injector: Injector,
  ): TInstance {
    try {
      const reflector = Injector.create({
        parent: injector,
        providers: [{ provide: instanceClass, useClass: instanceClass }],
      });

      return reflector.get(instanceClass);
    } catch (error) {
      Logger.instance.error(
        `Failed to create instance of ${instanceClass.name}.`,
      );
      throw error;
    }
  }
}
