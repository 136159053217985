import { Component, HostBinding, Input } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { Breakpoints } from '@compass/environment';

@Component({
  selector: 'cp-rp-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends PublicReportComponent {
  @Input()
  columns: string = '';

  @Input()
  mobileColumns: string = '';

  @HostBinding('style')
  get style(): object {
    return {
      gridTemplateColumns: this.getGridColumnStyle(
        this._breakpoints.current().largerThan('small')
          ? this.columns
          : this.mobileColumns,
      ),
    };
  }

  constructor(private readonly _breakpoints: Breakpoints) {
    super();
  }

  private getGridColumnStyle(columnTemplate: string): string {
    if (!columnTemplate) return '1fr';

    return columnTemplate.split(' ').join('fr ') + 'fr';
  }
}
