import { Component, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'cp-rp-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent extends PublicReportComponent implements OnInit {
  private _renderedTemplate?: string;

  @Input()
  showMeasureCount: boolean = false;

  get renderedTemplate(): string | undefined {
    return this._renderedTemplate;
  }

  constructor(private readonly _template: TemplateService) {
    super();
  }

  ngOnInit(): void {
    // Check if there is any template to render
    // Do regex, because publisher could have added
    // some break lines.
    if (!/\S/.test(this.template)) return;

    this._renderedTemplate = this._template.render(
      this.template,
      this.measureId,
    );
  }
}
