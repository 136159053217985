import { HttpRequestMethod } from './http-request-method';
import { HttpResponseType } from './http-response-type';

export const DefaultHttpRequestOptions: HttpRequestOptions = {
  method: HttpRequestMethod.Get,
  responseType: HttpResponseType.Json,
};

export interface HttpRequestOptions {
  method: HttpRequestMethod;
  responseType: HttpResponseType;
  body?: unknown;
}
