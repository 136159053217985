import { SupportedAttributeTypes } from './supported-attribute-types';

/**
 * Type of attribute validation function
 */
export type ComponentAttributeValidator = (
  value?: SupportedAttributeTypes,
) => boolean;

/**
 * Creates validation function for a pattern
 * @param regex Regex to use as validation pattern
 */
export function pattern(regex: RegExp): ComponentAttributeValidator {
  return (value?: SupportedAttributeTypes) => {
    return value === undefined || regex.test(value.toString());
  };
}

/**
 * Validation function that specifies values allowed
 * @param values Values to allow
 */
export function validValues(
  ...values: SupportedAttributeTypes[]
): ComponentAttributeValidator {
  return (value?: SupportedAttributeTypes) => {
    return !value || values.some(v => v === value);
  };
}

/**
 * Validation function that checks that attribute number is larger than or equal to the specified value
 * @param value Value for the attribute to be equal to or larger in order to be valid
 */
export function largerOrEqualTo(value: number): ComponentAttributeValidator {
  return (attrValue?: SupportedAttributeTypes) => {
    return typeof attrValue === 'number' && attrValue >= value;
  };
}
