import { ParticipantSelection } from '../models/report/participant-selection';

/**
 * Checks whether participant selection correctly answers a question.
 * Correct is defined as receiving maximum possible value for a scored question.
 * @param selection Participant
 */
export function isCorrectAnswer(selection: ParticipantSelection): boolean {
  return (
    selection.isScored && selection.maxRawValue > 0 && selection.isMaxValue
  );
}

/**
 * Checks whether participant selection incorrectly answers a question.
 * Incorrect is defined as receiving minimum possible value for a scored question.
 * @param selection Participant
 */
export function isIncorrectAnswer(selection: ParticipantSelection): boolean {
  return selection.isScored && selection.isMinValue;
}

/**
 * Checks whether participant selection partially answers a question.
 * Partially correct is defined as receiving partial value for a scored question.
 * @param selection Participant
 */
export function isPartiallyCorrectAnswer(
  selection: ParticipantSelection,
): boolean {
  return selection.isScored && selection.isPartialValue;
}
