import { Component, Input } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { Breakpoints } from '@compass/environment';

@Component({
  selector: 'cp-rp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends PublicReportComponent {
  @Input()
  email!: boolean;

  @Input()
  name!: boolean;

  @Input()
  phone!: boolean;

  constructor(protected readonly breakpoints: Breakpoints) {
    super();
  }

  protected print(): void {
    window.print();
  }
}
