import { Component, HostBinding } from '@angular/core';
import { Breakpoints } from '@compass/environment';
import { getCssClasses } from '@compass/helpers';
import { ObjectIndex } from '@compass/types';

@Component({
  selector: 'cp-rp-root',
  template: '<router-outlet/>',
})
export class AppComponent {
  @HostBinding('class')
  get class(): ObjectIndex<boolean> {
    return getCssClasses(
      {
        cssClass: this.getSizeClass(),
        prefix: 'v',
      },
      {
        cssClass: this.getDeviceClass(),
        prefix: 'v',
      },
    );
  }

  constructor(private readonly _breakpoints: Breakpoints) {}

  private getSizeClass(): string {
    const breakpoint = this._breakpoints.current();

    if (breakpoint.is('xsmall')) return 'xs';
    if (breakpoint.is('small')) return 'sm';
    if (breakpoint.is('medium')) return 'md';
    if (breakpoint.is('large')) return 'lg';
    if (breakpoint.is('xlarge')) return 'xl';

    return 'xxl';
  }

  private getDeviceClass(): string {
    const breakpoint = this._breakpoints.current();

    return breakpoint.smallerThan('medium') ? 'mobile' : 'desktop';
  }
}
