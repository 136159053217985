import { Injectable, Injector, Type } from '@angular/core';
import { CustomElementDef } from './custom-element-def';
import { registerCustomElement } from './custom-elements';

/**
 * Registry for adding custom elements to the platform
 */
@Injectable({
  providedIn: 'root',
})
export class ElementRegistry {
  /**
   * Prefix for custom elements
   */
  public readonly ELEMENT_PREFIX = 'hm-com-ce';
  private readonly _elements = new Map<string, CustomElementDef>();

  constructor(private readonly _injector: Injector) {}

  /**
   * Checks whether any custom elements were registered
   * @returns True if there is at least one element
   */
  hasElements(): boolean {
    return this._elements.size > 0;
  }

  /**
   * Checks whether custom element with name was registered
   * @param name Name of the element
   * @returns True if element exists otherwise false
   */
  hasElement(name: string): boolean {
    return this._elements.has(name);
  }

  /**
   * Gets element definition
   * @param name Name of the element
   * @returns Element definition if found or undefined
   */
  getElement(name: string): CustomElementDef | undefined {
    return this._elements.get(name);
  }

  /**
   * Registers a custom element
   * @param name Name of the element
   * @param element Element type
   * @param enableAttribute Conditional enable attribute
   */
  register(
    name: string,
    element: Type<unknown>,
    enableAttribute?: string,
  ): void {
    const elementDef: CustomElementDef = {
      name,
      tagName: this.ELEMENT_PREFIX + '-' + name,
      enableAttribute,
    };

    registerCustomElement(elementDef.tagName, element, this._injector);
    this._elements.set(elementDef.name, elementDef);
  }
}
