<div
  class="card"
  [class.hoverable]="goToPage"
  (click)="pageService.tryShow(goToPage ?? '', measureId)"
  (keydown)="pageService.tryShow(goToPage ?? '', measureId)"
  tabindex="-1">
  <h5 class="card-title text-center my-2 px-2" *ngIf="showLabel">
    {{ measure.label }}
  </h5>
  <div
    class="card-body pt-4 pt-md-3"
    [class.horizontal]="
      breakpoints.current().largerThan('small') && orientation === 'horizontal'
    ">
    <div class="svg-wrapper mb-4">
      <span class="score">{{ score.formattedScore }}</span>
      <span class="category">{{ scoringBucketForScore?.name ?? 'N/A' }}</span>
      <svg>
        <circle class="bg" cx="50%" cy="50%" r="48%" />
        <circle
          class="meter-1"
          cx="50%"
          cy="50%"
          r="48%"
          [ngStyle]="{
            strokeDasharray: chartConfig.progress * 220 + '% 720',
          }" />
      </svg>
    </div>

    <div class="d-flex flex-column justify-content-center mx-auto w-md-80">
      <div
        class="d-flex justify-content-center pt-1 mx-3 mx-md-0 pb-1 scoring-buckets"
        *ngFor="let bucket of scoringBuckets | orderBy: '-ceiling'">
        <div class="me-auto">{{ bucket.name }}</div>
        <div class="text-end">
          {{ bucket.floorFormatted }} - {{ bucket.ceilingFormatted }}
        </div>
      </div>
    </div>
  </div>
</div>
