import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '../../shared/services/report.service';
import { Logger } from '@compass/logging';
import { Title } from '@angular/platform-browser';
import { ApplicationEnvironment } from '@compass/environment';
import { KeycloakService } from 'keycloak-angular';
import { keycloakConfig } from '../../../environment/environment';

@Component({
  selector: 'cp-rp-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  protected loadInProgress: boolean = true;

  @Input()
  revisionCode?: string;

  @Input()
  reportId?: string;

  constructor(
    protected readonly reportService: ReportService,
    protected readonly environment: ApplicationEnvironment,
    private readonly _logger: Logger,
    private readonly _title: Title,
    private readonly _keycloak: KeycloakService,
  ) {}

  async ngOnInit(): Promise<void> {
    this._title.setTitle('Report loading...');

    await this.initializeAuthentication();

    try {
      await this.reportService.loadReport(this.revisionCode ?? '');
    } catch (error) {
      this._logger.error(error);
    }

    if (this.reportService.hasReport) {
      await this.reportService.goToReport(this.reportId);
      return;
    }

    this._title.setTitle('Report failed to load');
    this.loadInProgress = false;
    this.helloVincent();
  }

  private helloVincent(): void {
    if (!this.environment.isDevelopment) return;

    setTimeout(() => {
      const image = document.getElementById('vincent-vega');

      if (!image) return;

      image.style.display = 'block';
    }, 30_000);
  }

  private async initializeAuthentication(): Promise<void> {
    await this._keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        enableLogging: true,
      },
    });
  }
}
