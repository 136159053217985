import { Component, ContentChild, Input } from '@angular/core';
import { AccordionContentDirective } from '../../directives/accordion-content.directive';
import { AccordionChartDirective } from '../../directives/accordion-chart.directive';

@Component({
  selector: 'cp-rp-accordion-item',
  template: '',
})
export class AccordionItemComponent {
  @Input({ required: true })
  title: string = '';

  @Input()
  description?: string;

  @ContentChild(AccordionContentDirective)
  content?: AccordionContentDirective;

  @ContentChild(AccordionChartDirective)
  chart?: AccordionChartDirective;

  @Input()
  focusId?: string;
}
