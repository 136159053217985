import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';
import { ElementSizeSync } from '../helpers/element-size-sync';

/**
 * Directive for synchronizing element size to another element
 */
@Directive({
  selector: '[cpSizeSync]',
  standalone: true,
  exportAs: 'sizeSync',
})
export class SizeSyncDirective implements OnChanges, AfterViewChecked {
  /**
   * Source element for sizing
   */
  @Input('cpSizeSync')
  source?: HTMLElement;

  /**
   * Enables or disables syncing
   */
  @Input()
  syncEnabled: boolean = true;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _renderer: Renderer2,
  ) {}

  ngOnChanges(): void {
    this.refresh();
  }
  ngAfterViewChecked(): void {
    this.refresh();
  }

  /**
   * Refreshes the element size
   */
  public refresh(): void {
    if (!this.syncEnabled || !this.source) return;

    ElementSizeSync.sync(
      this._elementRef.nativeElement,
      this.source,
      this._renderer,
    );
  }
}
