import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
  standalone: true,
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private readonly _domSanitizer: DomSanitizer) {}

  transform(url: string, urlType: 'url' | 'resourceUrl' = 'url'): SafeUrl {
    return urlType === 'url'
      ? this._domSanitizer.bypassSecurityTrustUrl(url)
      : this._domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
