<ng-container *ngIf="loadInProgress">
  <cp-spinner>Loading report</cp-spinner>
</ng-container>
<ng-container *ngIf="!loadInProgress">
  <div
    class="d-flex vh-100 align-items-center justify-content-center bg-secondary">
    <div
      class="bg-white rounded d-flex align-items-center error-box w-md-75 w-xl-50 p-4">
      <i class="fa-thin text-danger fa-circle-exclamation pe-4"></i>
      <div class="d-flex flex-column">
        <span class="fs-3 mb-2">
          <ng-container *ngIf="!revisionCode">
            Incorrect report code
          </ng-container>
          <ng-container *ngIf="revisionCode">
            Report Load Failure
          </ng-container>
        </span>
        <p *ngIf="!revisionCode">
          The report code is missing or is not in valid format.
        </p>
        <ng-container *ngIf="revisionCode">
          <p>
            The report you requested could not be loaded. Possible problems
            include:
          </p>
          <ul>
            <li>Report was deleted or never existed, or</li>
            <li>Corrupt, invalid or expired report URL, or</li>
            <li>You do not have permission to view the report, or</li>
            <li>The account related to this report is disabled.</li>
          </ul>
        </ng-container>
      </div>
      @if (environment.isDevelopment) {
        <img
          id="vincent-vega"
          class="ms-auto"
          style="display: none; height: 175px"
          src="assets/images/not-found.gif"
          alt="Not Found" />
      }
    </div>
  </div>
</ng-container>
