import { Injectable } from '@angular/core';
import { Requester } from '@compass/http';
import { Logger } from '@compass/logging';
import { ReportData } from '../models/report/report-data';
import { Router } from '@angular/router';
import { undefinedOrEmpty } from '@compass/helpers';
import { BehaviorSubject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private _reportCode?: string;
  private _reportData?: ReportData;

  readonly reportData$ = new BehaviorSubject<ReportData | undefined>(
    this._reportData,
  );

  get hasReport(): boolean {
    return this._reportData !== undefined;
  }

  get reportData(): ReportData | undefined {
    return this._reportData;
  }

  set reportData(reportData: ReportData | undefined) {
    this._reportData = reportData;
    this._reportCode = reportData?.assessment?.reportCode;
    this.reportData$.next(reportData);

    this._logger.debug('Report data was set');
    this._logger.debug(reportData);
  }

  get reportCode(): string | undefined {
    return this._reportCode;
  }

  constructor(
    private readonly _logger: Logger,
    private readonly _requester: Requester,
    private readonly _router: Router,
    private readonly _keycloak: KeycloakService,
  ) {}

  async goToReport(reportId?: string): Promise<void> {
    if (!this.reportCode || !this.hasReport) return;

    const url = this.getReportUrl(this.reportCode, reportId);
    await this._router.navigateByUrl(url, { onSameUrlNavigation: 'reload' });
  }

  async loadReport(revisionCode: string): Promise<void> {
    if (!revisionCode) return;

    const response = await this._requester
      .get<ReportData>('report')
      .addRouteSegments(revisionCode)
      .send();

    if (response.status === 401) {
      await this._keycloak.login();
      return;
    }

    if (!response.isSuccess) {
      this._logger.error(response.errors);
      return;
    }

    if (undefinedOrEmpty(response.data?.completed?.reports)) {
      this._logger.warn('Report not loaded, because no reports were received.');
      return;
    }

    this.reportData = response.data;
  }

  private getReportUrl(reportCode: string, reportId?: string): string {
    let url = `/${reportCode}`;

    if (reportId) {
      url += `/${reportId}`;
    }

    return url;
  }
}
