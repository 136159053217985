import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { ObjectIndex, Position, Size } from '@compass/types';
import { getCssClasses } from '@compass/helpers';

@Component({
  selector: 'cp-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input()
  contentPosition: Position = 'bottom';

  @Input()
  size: Size = 'medium';

  @Input()
  fullscreen: boolean = false;

  @HostBinding('class')
  get hostClasses(): ObjectIndex<boolean> {
    return getCssClasses(
      {
        cssClass: 'spinner-fullscreen',
        condition: this.fullscreen,
      },
      {
        cssClass: this.contentPosition,
        prefix: 'spinner-content',
      },
      {
        cssClass: this.size,
        prefix: 'spinner',
      },
    );
  }
}
