<div class="switch" *ngIf="accordionService.hasItems()">
  @if (showLabel() && labelPosition() === 'start') {
    <span class="me-2">
      {{ label() }}
    </span>
  }
  <label>
    <input type="checkbox" [(ngModel)]="expandAll" />
    <span>&nbsp;</span>
  </label>
  @if (showLabel() && labelPosition() === 'end') {
    <span class="ms-2">
      {{ label() }}
    </span>
  }
</div>
