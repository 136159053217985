/**
 * Type representing JavaScript exception
 */
export interface JsException {
  /**
   * Exception message
   */
  message: string;

  /**
   * Exception stack trace
   */
  stack: string;
}

/**
 * Checks whether given error is JavaScript exception
 * @param ex Error to check
 */
export function isJsException(ex: unknown): ex is JsException {
  const obj = ex as JsException;

  return obj?.message !== undefined && obj?.stack !== undefined;
}
