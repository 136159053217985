import {
  Component,
  ComponentRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { ReportComponent } from '../../models/components/report-component';
import { PublicReportComponent } from '../../public-components/public-report-component';
import { Logger } from '@compass/logging';

@Component({
  selector: 'cp-rp-component-renderer',
  template: '',
  styles: [':host { display: none; }'],
})
export class ComponentRendererComponent implements OnChanges {
  /**
   * Components to render
   */
  @Input()
  components: ReportComponent[] = [];

  /**
   * Overrides the measure on this instance from the component definition
   */
  @Input()
  measureId?: string;

  constructor(
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _logger: Logger,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['components']) return;

    this._viewContainerRef.clear();

    for (const componentDef of this.components) {
      if (!componentDef.validationStatus.isValid) {
        this._logger.warn(
          `Component ${componentDef.name} is invalid and it will not be rendered.`,
        );
        this._logger.error(componentDef.validationStatus.errors);
        continue;
      }

      if (!componentDef.canRender) continue;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const cmp = this._viewContainerRef.createComponent(
        componentDef.component!,
      );

      this.setAttributesOnComponentInstance(componentDef, cmp);
    }
  }

  private setAttributesOnComponentInstance(
    componentDef: ReportComponent,
    component: ComponentRef<PublicReportComponent>,
  ): void {
    // Set the component def input
    component.setInput('component', componentDef);
    // Set the measure ID override if set & component does not specify explicit measure
    if (this.measureId && !componentDef.explicitMeasure()) {
      component.setInput('measureId', this.measureId);
    }

    if (!componentDef.attributes) return;
    // Now set all the attributes
    for (const attr in componentDef.attributes) {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-prototype-builtins
        !component.instance.constructor['ɵcmp'].inputs.hasOwnProperty(attr)
      ) {
        if (!attr.startsWith('__')) {
          this._logger.warn(
            `Attribute '${attr}' has defined value of '${componentDef.attributes[attr]}', but it is not a known input of ${component.componentType.name} and it will not be set.`,
          );
        }

        continue;
      }

      const attributeValue = componentDef.attributes[attr];

      // Do not set the attribute if it is not set OR if empty string
      if (
        attributeValue === undefined ||
        attributeValue === null ||
        (typeof attributeValue === 'string' && attributeValue.length === 0)
      ) {
        continue;
      }

      component.setInput(attr, componentDef.attributes[attr]);
    }
  }
}
