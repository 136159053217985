<div class="tag-wrapper px-2 px-md-4" *ngIf="tag && tagItems.length > 0">
  <div class="badge bg-primary">
    {{ label ? label : (tag | replace: '-' : ' ' | titlecase) }}
  </div>

  <ng-container [ngSwitch]="display">
    <ng-container *ngSwitchCase="'list'">
      <!-- List display -->
      <ng-container *ngTemplateOutlet="listDisplay" />
    </ng-container>
    <ng-container *ngSwitchCase="'paragraph'">
      <!-- Paragraph display -->
      <p
        class="tag-text mb-2"
        *ngFor="let item of tagItems"
        [innerHTML]="item.body"></p>
    </ng-container>
    <ng-container *ngSwitchCase="'inline'">
      <!-- Inline display -->
      <ng-container *ngFor="let item of tagItems">
        <span class="inline-content" [innerHTML]="item.body"></span>
        <span>&nbsp;</span>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <!-- Default display not set -->
      <p
        class="tag-text mb-2"
        *ngIf="tagItems.length === 1; else nonSingleTagItem"
        [innerHTML]="tagItems[0].body"></p>

      <ng-template #nonSingleTagItem>
        <ng-container *ngTemplateOutlet="listDisplay" />
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #listDisplay>
  <ul [class]="listStyle">
    <li
      *ngFor="let item of tagItems"
      class="mb-3 ms-3 tag-text"
      [innerHTML]="item.body"></li>
  </ul>
</ng-template>
