import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[cpRpFocusable]',
})
export class FocusableDirective {
  @Input()
  cpRpFocusable?: string;

  @HostBinding('attr.data-focusable')
  get focusableId(): string | undefined {
    return this.cpRpFocusable;
  }
}
