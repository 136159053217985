import { Injector, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
/**
 * Creates and registers new custom element with the DOM
 * @param tagName Name of the tag to use
 * @param element Element type to bind to the tag name
 * @param injector Injector for DI
 */
export function registerCustomElement(
  tagName: string,
  element: Type<unknown>,
  injector: Injector,
): void {
  const existingElement = customElements.get(tagName);
  if (existingElement) {
    return;
  }

  const elementDefinition = createCustomElement(element, { injector });

  customElements.define(tagName, elementDefinition);
}
