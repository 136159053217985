import { Component, input } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { AccordionService } from '../../services/accordion.service';

@Component({
  selector: 'cp-rp-accordion-toggle-button',
  templateUrl: './accordion-toggle-button.component.html',
  styleUrls: ['./accordion-toggle-button.component.scss'],
})
export class AccordionToggleButtonComponent extends PublicReportComponent {
  protected readonly label = input('Expand all');
  protected readonly showLabel = input(true);
  protected readonly labelPosition = input<'start' | 'end'>('start');

  protected get expandAll(): boolean {
    return this.accordionService.areAllExpanded();
  }

  protected set expandAll(value: boolean) {
    if (value) {
      this.accordionService.expandAll();
    } else {
      this.accordionService.collapseAll();
    }
  }

  constructor(protected readonly accordionService: AccordionService) {
    super();
  }
}
