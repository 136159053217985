import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe that replaces values in a string.
 * It can take in a value to replace or regular expression.
 * Default replacement value is empty string.
 */
@Pipe({
  name: 'replace',
  standalone: true,
})
export class ReplacePipe implements PipeTransform {
  transform(
    value: string,
    replaceWhat: string,
    replaceWith: string = '',
  ): string {
    const regex = new RegExp(replaceWhat, 'g');

    return value.replaceAll(regex, replaceWith);
  }
}
