import {
  distinctUntilChanged,
  fromEvent,
  map,
  Observable,
  shareReplay,
  startWith,
} from 'rxjs';
import { BreakpointDef, sizeBreakpoints } from './breakpoint-def';
import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class Breakpoints {
  readonly current$: Observable<BreakpointDef> = fromEvent(
    window,
    'resize',
  ).pipe(
    startWith(this.getScreenBreakpoint()),
    map(() => this.getScreenBreakpoint()),
    distinctUntilChanged(),
    shareReplay(1),
  );
  readonly current: Signal<BreakpointDef> = toSignal(this.current$, {
    initialValue: this.getScreenBreakpoint(),
  });

  private getScreenBreakpoint(): BreakpointDef {
    for (const breakpoint of sizeBreakpoints) {
      if (window.matchMedia(breakpoint.mediaQuery).matches) {
        return breakpoint;
      }
    }

    return sizeBreakpoints[0];
  }
}
