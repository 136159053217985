/**
 * Checks whether array is undefined or empty
 * @param array Array to check
 */
export function undefinedOrEmpty(array: unknown[] | undefined): boolean {
  return !array || array.length === 0;
}

/**
 * Gets all elements from an array that are not null or undefined
 * @param array Array to filter from
 */
export function filterNotNull<T>(array: (T | undefined)[]): T[] {
  return array.filter(i => i !== undefined && i !== null) as T[];
}

/**
 * Filters out any falsy values from the given array.
 *
 * @param {Array<T | undefined>} array - The array to filter.
 * @returns {Array<T>} - The filtered array.
 */
export function filterTruthy<T>(array: (T | undefined)[]): T[] {
  return array.filter(i => i) as T[];
}

/**
 * Removes duplicate values from an array
 * @param array Array to remove duplicates from
 * @param keySelector Key selection of comparator
 */
export function unique<T>(array: T[], keySelector?: (item: T) => unknown): T[] {
  return array.reduce((accumulator: T[], currentValue: T) => {
    const index = keySelector
      ? accumulator.findIndex(
          arrayItem => keySelector(arrayItem) === keySelector(currentValue),
        )
      : accumulator.indexOf(currentValue);

    if (index === -1) {
      accumulator.push(currentValue);
    }

    return accumulator;
  }, []);
}

/**
 * Creates an array from items if it is not already
 * @param items Array items
 */
export function toArray<T>(items?: T | T[]): T[] {
  if (!items) return [];

  return Array.isArray(items) ? items : [items];
}

/**
 * Creates an array of number from a range
 * @param start Start of the array
 * @param end End of the array
 * @param step Step between each number. Default is 1.
 * @param maxArrayLength Maximum length of the array. When longer step is doubled until sufficient length is found. Defaults to unlimited.
 */
export function arrayFromRange(
  start: number,
  end: number,
  step: number = 1,
  maxArrayLength?: number,
): number[] {
  while (maxArrayLength && (end - start) / step + 1 > maxArrayLength) {
    step *= 2;
  }

  const middleOfArray = unique(
    Array.from(
      { length: (end - start) / step - 1 },
      (_, index) => start + (index + 1) * step,
    ),
  );

  return [start, end, ...middleOfArray].sort((a, b) => a - b);
}
