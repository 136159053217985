import { Environment } from '@compass/environment';
import { AppConfig } from '../app-config';
import { KeycloakConfig } from 'keycloak-js';

export const keycloakConfig: KeycloakConfig = {
  realm: 'HighMatch',
  clientId: 'admin-ui',
  url: 'https://login.highmatch.com/auth',
};
export const appConfig: AppConfig = {
  environment: Environment.Production,
  minLogLevel: 'info',
  baseApiUrl: 'https://api.compass.highmatch.com/p/',
  allowedMessageOrigins: [
    'https:\\/\\/([\\w\\d-]+\\.)*highmatch\\.com',
    'https:\\/\\/([\\w\\d-]+\\.)*asmt\\.app',
  ],
  appName: 'Report',
};
