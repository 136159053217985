import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts an index to a letter in an alphabet.
 * Index starts at 1. E.g. 1 = A, 2 = B, etc.
 */
@Pipe({
  name: 'indexLetter',
  standalone: true,
})
export class IndexLetterPipe implements PipeTransform {
  private readonly _letters: string[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  transform(index: number): string {
    return index < 1 || index > this._letters.length
      ? ''
      : this._letters[index - 1];
  }
}
