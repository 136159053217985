import { ApplicationInfo } from './application-info';

/**
 * Well defined app environments
 */
export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

/**
 * Service for getting information about the app environment
 */
export class ApplicationEnvironment {
  private readonly _isDevelopment: boolean;
  private readonly _isStaging: boolean;
  private readonly _isProduction: boolean;

  /**
   * Indicator whether current environment is development
   */
  get isDevelopment(): boolean {
    return this._isDevelopment;
  }

  /**
   * Indicator whether current environment is staging
   */
  get isStaging(): boolean {
    return this._isStaging;
  }

  /**
   * Indicator whether current environment is production
   */
  get isProduction(): boolean {
    return this._isProduction;
  }

  /**
   * Application version
   */
  get version(): string {
    return this._appInfo.version;
  }

  /**
   * Application environment
   */
  get environment(): string {
    return this._appInfo.environment;
  }

  /**
   * Friendly application name
   */
  get name(): string {
    return this._appInfo.name;
  }

  constructor(private readonly _appInfo: ApplicationInfo) {
    this._isDevelopment = _appInfo.environment === Environment.Development;
    this._isStaging = _appInfo.environment === Environment.Staging;
    this._isProduction = _appInfo.environment === Environment.Production;
  }
}
