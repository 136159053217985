import { Injectable, OnDestroy } from '@angular/core';
import { ReportService } from './report.service';
import { Subscription } from 'rxjs';
import { ReportData } from '../models/report/report-data';
import { Measure } from '../models/report/measure';
import { ROOT_MEASURE_ID } from '../constants/measures';
import { filterNotNull } from '@compass/helpers';
import { Score } from '../models/report/score';
import { ScoringBucket } from '../models/report/scoring-bucket';

interface MeasureTree {
  measure: Measure;
  score: Score;
  target?: ScoringBucket;
  children: MeasureTree[];
}

/**
 * Service for providing measures for an assessment
 */
@Injectable({
  providedIn: 'root',
})
export class MeasureService implements OnDestroy {
  private readonly _reportDataSub?: Subscription;

  private _measures: Measure[] = [];
  private _measureTree?: MeasureTree;

  /**
   * Measures for an assessment sorted
   */
  get measures(): Measure[] {
    return this._measures;
  }

  constructor(report: ReportService) {
    this._reportDataSub = report.reportData$.subscribe(
      this.reportDataReceived.bind(this),
    );
  }

  ngOnDestroy(): void {
    this._reportDataSub?.unsubscribe();
  }

  /**
   * Gets measure from a parent down
   * @param parent Measure to start search from. Default is 'root'.
   * @param includeParent Whether to include the parent measure. Default is true.
   */
  getMeasures(
    parent: string = ROOT_MEASURE_ID,
    includeParent: boolean = true,
  ): Measure[] {
    const measureTree = this.findMeasureInTree(parent, this._measureTree);

    if (!measureTree) return [];

    return includeParent
      ? this.flattenMeasureTree(measureTree)
      : measureTree.children.map(c => this.flattenMeasureTree(c)).flat();
  }

  /**
   * Gets measure by ID
   * @param measureId ID of the measuer
   */
  getMeasureById(measureId: string): Measure | undefined {
    return this.findMeasureInTree(measureId)?.measure;
  }

  /**
   * Checks whether measure with ID exists
   * @param measureId Measure ID to check
   */
  hasMeasure(measureId: string): boolean {
    return this._measures.some(m => m.measureId === measureId);
  }

  /**
   * Gets a score for a measure with ID
   * @param measureId ID of the measure
   */
  getScoreForMeasure(measureId: string): Score | undefined {
    return this.findMeasureInTree(measureId)?.score;
  }

  /**
   * Gets a target for a measure with ID
   * @param measureId ID of the measure
   */
  getTargetForMeasure(measureId: string): ScoringBucket | undefined {
    return this.findMeasureInTree(measureId)?.target;
  }

  private findMeasureInTree(
    measureId: string,
    measureTree?: MeasureTree,
  ): MeasureTree | undefined {
    measureTree ??= this._measureTree;

    if (!measureTree) return undefined;

    if (measureTree.measure.measureId === measureId) {
      return measureTree;
    }

    for (const tree of measureTree.children) {
      const childMeasure = this.findMeasureInTree(measureId, tree);

      if (childMeasure) {
        return childMeasure;
      }
    }

    return undefined;
  }

  private reportDataReceived(data?: ReportData): void {
    // If there is no data then reset the values and return...
    if (!data) {
      this._measures = [];
      this._measureTree = undefined;
      return;
    }

    this._measureTree = this.constructMeasureTree(
      data.assessment.measures,
      data.scoring.targets,
      data.completed.scores,
    );
    this._measures = this._measureTree
      ? this.flattenMeasureTree(this._measureTree)
      : [];
  }

  private flattenMeasureTree(measureTree: MeasureTree): Measure[] {
    const items: Measure[] = [measureTree.measure];

    for (const childTree of measureTree.children) {
      items.push(...this.flattenMeasureTree(childTree));
    }

    return items;
  }

  private constructMeasureTree(
    measures: Measure[],
    targets: ScoringBucket[],
    scores: Score[],
    measureId: string = ROOT_MEASURE_ID,
  ): MeasureTree | undefined {
    const measure = measures.find(m => m.measureId === measureId);

    if (!measure) return undefined;

    const children = measures
      .filter(m => m.parentMeasureId === measureId)
      .map(c =>
        this.constructMeasureTree(measures, targets, scores, c.measureId),
      );

    return {
      measure,
      children: filterNotNull(children).sort((a, b) =>
        this.measureCompareFn(a.measure, b.measure),
      ),
      score: scores.find(s => s.measureId === measureId)!,
      target: targets.find(t => t.measureId === measureId),
    };
  }

  private measureCompareFn(a: Measure, b: Measure): number {
    return (
      a.sortOrder - b.sortOrder ||
      a.label.localeCompare(b.label) ||
      a.measureId.localeCompare(b.measureId)
    );
  }
}
