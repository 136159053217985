<ul class="nav no-container-padding d-print-none" *ngIf="pageService.hasPages">
  <li class="nav-item" *ngFor="let page of pageService.pages">
    <button
      class="nav-link"
      [class.active]="page.pageId === pageService.activePage?.pageId"
      (click)="pageService.show(page)">
      {{ page.name }}
    </button>
  </li>
</ul>
