import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApplicationInfo, defaultApplicationInfo } from './application-info';
import { ApplicationEnvironment } from './application-environment.service';

@NgModule()
export class ApplicationEnvironmentModule {
  public static forRoot(
    info: Partial<ApplicationInfo>,
  ): ModuleWithProviders<ApplicationEnvironmentModule> {
    const appInfo: ApplicationInfo = {
      ...defaultApplicationInfo,
      ...info,
    };

    return {
      ngModule: ApplicationEnvironmentModule,
      providers: [
        {
          provide: ApplicationEnvironment,
          useValue: new ApplicationEnvironment(appInfo),
        },
      ],
    };
  }
}
