/**
 * Type alias for sizing
 */
export type Size =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge';

/**
 * Gets ordinal index for a size
 * @param size Size to get index for
 */
export function sizeIndex(size: Size): number {
  switch (size) {
    case 'xsmall':
      return 1;
    case 'small':
      return 2;
    case 'medium':
      return 3;
    case 'large':
      return 4;
    case 'xlarge':
      return 5;
    case 'xxlarge':
      return 6;
  }
}
