import { Environment } from './application-environment.service';

/**
 * Application information
 */
export interface ApplicationInfo {
  environment: string;
  version: string;
  name: string;
}

export const defaultApplicationInfo: ApplicationInfo = {
  environment: Environment.Development,
  version: '0.0.0',
  name: 'N/A',
};
