/**
 * Lazily loads a value when accessed
 */
export class Lazy<T extends NonNullable<unknown>> {
  private _value?: T;

  /**
   * Creates an instance of lazy loader
   * @param _loadFn Function that loads the value when needed
   */
  constructor(private readonly _loadFn: () => T) {}

  /**
   * Value of the lazy object
   */
  get value(): T {
    if (!this._value) {
      this._value = this._loadFn();
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._value!;
  }

  /**
   * Clears the value
   */
  clear(): void {
    this._value = undefined;
  }
}
