import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ReportService } from '../../shared/services/report.service';
import { Report } from '../../shared/models/report/report';

@Component({
  selector: 'cp-rp-report-view',
  templateUrl: './report-view.component.html',
})
export class ReportViewComponent implements OnChanges, OnInit {
  protected report?: Report;

  @Input()
  reportId?: string;

  constructor(protected readonly reportService: ReportService) {}

  ngOnInit(): void {
    this.report = this.reportService.reportData?.completed.reports.find(
      r => r.isDefault,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['reportId']) return;

    if (this.reportId) {
      this.report = this.reportService.reportData?.completed.reports.find(
        r => r.reportId === this.reportId,
      );
    }

    if (this.report) return;

    this.report = this.reportService.reportData?.completed.reports.find(
      r => r.isDefault,
    );
  }
}
