import { HttpRequest } from '../helpers';
import { RequestBuilder } from './request-builder';
import { SuccessApiResponse, ErrorApiResponse } from '../responses';

/**
 * Represents a RequestBuilderWithBody class that extends RequestBuilder.
 * It provides methods to build and send HTTP requests with a body.
 */
export class RequestBuilderWithBody<
  TResult = unknown,
> extends RequestBuilder<TResult> {
  private _body?: unknown;

  /**
   * Sets the body of the request.
   *
   * @param {unknown} body - The body of the request.
   * @return {RequestBuilderWithBody<TResult>} - The updated request builder with body.
   */
  withBody(body: unknown): this {
    this._body = body;

    return this;
  }

  override build(): HttpRequest<TResult> {
    const route = this.getRemoteUrl();

    return new HttpRequest<TResult>(this.httpClient, route, {
      ...this.getHttpRequestOptions(),
      body: this._body,
    });
  }

  override send(
    body?: unknown,
  ): Promise<SuccessApiResponse<TResult> | ErrorApiResponse> {
    if (body) {
      this.withBody(body);
    }

    return this.build().send();
  }
}
