/**
 * Attribute key for 'template' attribute
 */
export const COMPONENT_TEMPLATE_ATTR = '__template__';

/**
 * Attribute key for 'measure' attribute
 */
export const COMPONENT_MEASURE_SCOPE_ATTR = '__measure-scope__';

/**
 * Attribute key for 'declaration' attribute
 */
export const COMPONENT_DECLARATION_ATTR = '__declaration__';

/**
 * Attribute key for 'supplemental measures' attribute
 */
export const COMPONENT_SUPPLEMENTAL_MEASURES = '__supplemental-measures__';

/**
 * Attribute key for 'class list' attribute
 */
export const COMPONENT_CLASS_LIST = '__class-list__';
