import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Optional,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {
  HTMLElement as ParserHtmlElement,
  Node as HtmlParserNode,
  parse,
} from 'node-html-parser';
import { CustomElementDef, ElementRegistry } from '@compass/elements';

@Component({
  selector: 'cp-html-renderer',
  template: '',
  styleUrls: ['./html-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class HtmlRendererComponent implements OnChanges {
  private readonly _host: HTMLElement;

  /**
   * Content to render
   */
  @Input()
  content?: string;

  /**
   * Whether to allow script rendering. Default is false.
   */
  @Input()
  allowScripts: boolean = false;

  @Output()
  contentChange = new EventEmitter<string>();

  constructor(
    element: ElementRef,
    private readonly _renderer: Renderer2,
    @Optional()
    private readonly _elementRegistry?: ElementRegistry,
  ) {
    this._host = element.nativeElement;
  }

  ngOnChanges(): void {
    this.renderContent();
    this.contentChange.emit(this.content);
  }

  /**
   * Renders the content
   * @private
   */
  private renderContent(): void {
    const content = this.content ?? '';

    this.clearHost();

    this.renderContentInComponent(content);
  }

  /**
   * Renders content in the component
   * @param content Content to render
   * @private
   */
  private renderContentInComponent(content: string): void {
    const parsedHtml = parse(content);
    const html = this.processHtml(parsedHtml);

    this._renderer.setProperty(this._host, 'innerHTML', html);

    // If we are not allowing scripts then there is no reason
    // to execute the following code.
    if (!this.allowScripts) return;

    const scripts = this.processScripts(parsedHtml.childNodes);
    this.addScriptsToHost(scripts);
  }

  /**
   * Clears all host content
   * @private
   */
  private clearHost(): void {
    this._renderer.setProperty(this._host, 'innerHTML', null);
  }
  private addScriptsToHost(scripts: string[]): void {
    for (const script of scripts) {
      const sc = document.createElement('script');
      sc.type = 'text/javascript';
      sc.textContent = script;

      this._renderer.appendChild(this._host, sc);
    }
  }

  /**
   * Extracts script tags from parsed HTML
   * @param nodes Nodes to search for scripts
   * @private
   */
  private processScripts(nodes: HtmlParserNode[]): string[] {
    const scripts: string[] = [];

    for (const node of nodes) {
      if (!(node instanceof ParserHtmlElement)) continue;

      if (node.rawTagName === 'script') {
        if (node.hasAttribute('disabled')) continue;
        scripts.push(node.innerText);
      } else if (node.childNodes.length > 0) {
        scripts.push(...this.processScripts(node.childNodes));
      }
    }
    return scripts;
  }

  /**
   * Process parsed HTML for any further modification
   * @param html HTML to process
   * @private
   */
  private processHtml(html: ParserHtmlElement): string {
    // If there are no custom elements then we don't need to
    // process anything. Return the input.
    if (!this._elementRegistry || !this._elementRegistry.hasElements())
      return html.toString();

    this.processCustomComponents(html.childNodes);

    return html.toString();
  }

  /**
   * Detected nodes that are registered as custom components to custom component
   * @param nodes Nodes to search for custom components
   * @private
   */
  private processCustomComponents(nodes: HtmlParserNode[]): void {
    if (!this._elementRegistry) return;

    for (const node of nodes) {
      if (!(node instanceof ParserHtmlElement)) continue;

      const customElement = this._elementRegistry.getElement(node.rawTagName);
      if (
        customElement &&
        this.isCustomElementEnabled(node, customElement.enableAttribute)
      ) {
        this.convertToCustomComponent(node, customElement);
      }

      if (node.childNodes.length > 0) {
        this.processCustomComponents(node.childNodes);
      }
    }
  }

  /**
   * Convert a node to custom component
   * @param node Node to convert
   * @param customElement Custom element definition
   * @private
   */
  private convertToCustomComponent(
    node: ParserHtmlElement,
    customElement: CustomElementDef,
  ): void {
    node.rawTagName = customElement.tagName;

    // Convert cameCase attribute names to kebab-case, otherwise,
    // they will not be bound to Angular inputs.
    for (const attributeName in node.attributes) {
      const value = node.getAttribute(attributeName);

      if (value === undefined) continue;

      const kebabCaseKey = attributeName
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();

      node.setAttribute(kebabCaseKey, value);
    }
  }

  private isCustomElementEnabled(
    node: ParserHtmlElement,
    enableAttribute?: string,
  ): boolean {
    // If there is no special attribute defined
    // then it is enabled.
    if (!enableAttribute || !node.hasAttribute(enableAttribute)) return true;

    // Unless the attribute is set to 'false' then it is enabled
    return node.getAttribute(enableAttribute)?.toLowerCase() !== 'false';
  }
}
