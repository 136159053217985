/**
 * Scaling options definition
 */
export interface ScalingOptions {
  scalingFactor: number;
  orientation: 'horizontal' | 'vertical';
  targetSelector?: string;
}

/**
 * Default scaling options
 */
export function defaultScalingOptions(): ScalingOptions {
  return {
    scalingFactor: 1,
    orientation: 'vertical',
  };
}
