import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Question } from '../../models/report/question';
import { ParticipantSelection } from '../../models/report/participant-selection';
import {
  isCorrectAnswer,
  isIncorrectAnswer,
  isPartiallyCorrectAnswer,
} from '../../helpers/participant-selection-helpers';

@Component({
  selector: 'cp-rp-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['question-answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionAnswerComponent {
  @Input({ required: true })
  question!: Question;

  @Input()
  questionNumber: number = 0;

  @Input()
  participantSelection?: ParticipantSelection;

  protected readonly isCorrectAnswer = isCorrectAnswer;
  protected readonly isIncorrectAnswer = isIncorrectAnswer;
  protected readonly isPartiallyCorrectAnswer = isPartiallyCorrectAnswer;

  protected isChoiceSelected(choiceId: string): boolean {
    if (!this.participantSelection) return false;

    return this.participantSelection.choices.some(c => c.choiceId === choiceId);
  }

  protected isChoiceCorrect(choiceId: string): boolean {
    const choice = this.question.choices.find(c => c.choiceId === choiceId);

    if (!choice) return false;

    return choice.valueWhenSelected > 0;
  }
}
