import { ReportComponentDef } from './report-component-def';
import { PublicReportComponent } from '../../public-components/public-report-component';
import { TemplateComponentDef } from '../template/template-component-def';
import { TemplateParser } from '../../services/template-parser';
import { ObjectIndex } from '@compass/types';
import { SupportedAttributeTypes } from './supported-attribute-types';
import {
  COMPONENT_MEASURE_SCOPE_ATTR,
  COMPONENT_SUPPLEMENTAL_MEASURES,
} from './global-attributes';
import { ROOT_MEASURE_ID } from '../../constants/measures';
import { ComponentValidator } from '../../validators/component-validator';
import { ReportService } from '../../services/report.service';

export class ReportComponent {
  private readonly _componentDefinition: ReportComponentDef;
  private readonly _parent?: ReportComponent;

  private _validationErrors: string[] = [];

  readonly id = crypto.randomUUID();
  readonly children: ReportComponent[];
  readonly attributes: ObjectIndex<SupportedAttributeTypes>;
  readonly supplementalMeasures: string[];
  readonly name: string;

  get component():
    | (new (...args: unknown[]) => PublicReportComponent)
    | undefined {
    return this._componentDefinition.component;
  }

  get validationStatus(): { isValid: boolean; errors: string[] } {
    return {
      isValid: this._validationErrors.length === 0,
      errors: this._validationErrors,
    };
  }

  get canRender(): boolean {
    return this._validationErrors.length === 0 && this.component !== undefined;
  }

  get parent(): ReportComponent | undefined {
    return this._parent;
  }

  get isTopComponent(): boolean {
    return this.parent === undefined || this.parent === null;
  }

  get measureScope(): string {
    const scopeAttr = this.attributes[COMPONENT_MEASURE_SCOPE_ATTR];
    if (scopeAttr && typeof scopeAttr === 'string') {
      return scopeAttr;
    }

    // No scope is defined... Check if we are top component and if so then scope is root
    // If not top component then return the scope of the parent
    return this.parent === undefined
      ? ROOT_MEASURE_ID
      : this.parent.measureScope;
  }

  private constructor(
    templateDefinition: TemplateComponentDef,
    parent?: ReportComponent,
  ) {
    // Set private fields
    this._componentDefinition = templateDefinition.componentDef;
    this._parent = parent;

    // Set public properties
    this.attributes = Object.freeze(templateDefinition.attributes);
    this.name = this.component?.name ?? this._componentDefinition.tagName;
    this.children = templateDefinition.children.map(
      c => new ReportComponent(c, this),
    );
    this.supplementalMeasures = templateDefinition.attributes[
      COMPONENT_SUPPLEMENTAL_MEASURES
    ]
      ? (
          templateDefinition.attributes[
            COMPONENT_SUPPLEMENTAL_MEASURES
          ] as string
        ).split(',')
      : [];
  }

  /**
   * Checks whether attribute with given key exists
   * @param key Key to check
   */
  hasAttribute(key: string): boolean {
    return this.attributes[key] !== undefined;
  }

  /**
   * Checks whether component defines measure scope explicitly
   */
  explicitMeasure(): boolean {
    return this.hasAttribute(COMPONENT_MEASURE_SCOPE_ATTR);
  }

  /**
   * Gets an attribute by key as string value
   * @param key Key to look for
   */
  getAttributeAsString(key: string): string | undefined {
    const attrValue = this.attributes[key];

    return attrValue && typeof attrValue === 'string' ? attrValue : undefined;
  }

  /**
   * Gets an attribute by key as boolean value
   * @param key Key to look for
   */
  getAttributeAsBoolean(key: string): boolean | undefined {
    const attrValue = this.attributes[key];

    if (typeof attrValue === 'boolean') {
      return attrValue;
    }

    if (typeof attrValue === 'number') {
      return !!attrValue;
    }

    if (attrValue === 'true') {
      return true;
    } else if (attrValue === 'false') {
      return false;
    }

    return undefined;
  }

  public validate(reportService: ReportService): void {
    const validationErrors = new ComponentValidator(reportService).validate(
      this,
    );

    this._validationErrors = [];
    for (const key in validationErrors) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorsForProperty = validationErrors[key];

      if (Array.isArray(errorsForProperty)) {
        this._validationErrors.push(...errorsForProperty);
      } else {
        this._validationErrors.push(errorsForProperty);
      }
    }
  }

  public static fromTemplate(template: string): ReportComponent[] {
    return TemplateParser.parse(template).map(cmp => new ReportComponent(cmp));
  }
}
