import { Injectable } from '@angular/core';
import { PageComponent } from '../public-components/page/page.component';
import { Title } from '@angular/platform-browser';
import { ReportService } from './report.service';

/**
 * Service for managing report pages
 */
@Injectable({
  providedIn: 'root',
})
export class PageService {
  private _pages: PageComponent[] = [];
  private _activePage?: PageComponent;

  get activePage(): PageComponent | undefined {
    return this._activePage;
  }

  get pages(): PageComponent[] {
    return this._pages;
  }

  get hasPages(): boolean {
    return this._pages.length > 0;
  }

  constructor(
    private readonly _title: Title,
    private readonly _report: ReportService,
  ) {}

  registerPage(page: PageComponent): void {
    if (this._pages.some(p => p.pageId === page.pageId)) return;

    this._pages.push(page);

    if (!this._activePage || (page.open && !this._activePage.open)) {
      this.show(page);
    }
  }

  show(page: PageComponent, measureFocus?: string): void {
    if (!this._pages.some(p => p.pageId === page.pageId)) return;

    this._activePage = page;

    if (measureFocus) {
      page.focusMeasure(measureFocus);
    }

    this.setTitleForPage();
  }

  tryShow(pageName: string, measureFocus?: string): void {
    const page = this.getPageByName(pageName);

    if (!page) return;

    this.show(page, measureFocus);
  }

  clear(): void {
    this._pages = [];
    this._activePage = undefined;
  }

  getPageByName(pageName: string): PageComponent | undefined {
    pageName = pageName.toLowerCase();
    return this._pages.find(p => p.name.toLowerCase() === pageName);
  }

  private setTitleForPage(): void {
    let title = `${this._report.reportData?.participant.nameFull}`;

    if (this.activePage) {
      title += ` | ${this.activePage.name}`;
    }

    this._title.setTitle(title);
  }
}
