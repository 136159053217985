import { Component, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { Measure } from '../../models/report/measure';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'cp-rp-measure',
  templateUrl: './measure.component.html',
})
export class MeasureComponent extends PublicReportComponent implements OnInit {
  protected measures: Measure[] = [];

  @Input()
  title?: string;

  @Input()
  description: string = '';

  @Input()
  showSelf: boolean = false;

  @Input()
  showChildren: boolean = true;

  @Input()
  showChart: boolean = true;

  @Input()
  showChartValue: boolean = true;

  @Input()
  showChartTails: boolean = true;

  @Input()
  chartTailStart?: string;

  @Input()
  chartTailEnd?: string;

  @Input()
  collapsed: boolean = false;

  constructor(protected readonly templateService: TemplateService) {
    super();
  }

  ngOnInit(): void {
    this.measures = this.getMeasuresToShow();
  }

  private getMeasuresToShow(): Measure[] {
    const measures = this.measureService.getMeasures(
      this.measureId,
      this.showSelf,
    );

    return this.showChildren
      ? measures
      : measures.filter(m => m.measureId === this.measureId);
  }
}
