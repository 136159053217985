<div
  class="badge question-badge"
  *ngIf="participantSelection"
  [ngClass]="{
    'question-badge-correct': isCorrectAnswer(participantSelection),
    'question-badge-incorrect': isIncorrectAnswer(participantSelection),
    'question-badge-partially-correct':
      isPartiallyCorrectAnswer(participantSelection),
    'question-badge-not-answered': participantSelection.noChoicesRecorded,
    'question-badge-not-scored': !participantSelection.isScored,
  }"></div>
<span class="mx-1 mx-md-2 fw-semibold">{{ questionNumber }}.</span>
<div class="flex-fill">
  <cp-html-renderer
    cpScaleSource
    [requiresAttributeSet]="true"
    (contentChange)="scaleSource.refresh()"
    class="px-3"
    [content]="question.body"
    #scaleSource="cpScaleSource" />
  <small class="px-3 my-3 d-block fst-italic">{{
    question.selectInstructions
  }}</small>
  <div
    class="answer-choice mb-3 px-3 py-3 py-md-2"
    [class.selected]="isChoiceSelected(choice.choiceId)"
    [class.correct]="isChoiceCorrect(choice.choiceId)"
    *ngFor="let choice of question.choices; let i = index">
    <span class="me-2">{{ i + 1 | indexLetter }}.</span>
    <cp-html-renderer
      cpScaleTarget
      class="flex-fill choice-body"
      [content]="choice.body" />
    <span class="badge rounded-pill choice-badge correct-badge">
      Selected
      <i class="fa-solid fa-check fa-lg ms-2"></i>
    </span>
    <span class="badge rounded-pill choice-badge incorrect-badge">
      Selected
      <i class="fa-solid fa-xmark fa-lg ms-2"></i>
    </span>
    <span class="badge rounded-pill choice-badge correct-not-selected-badge">
      Correct response, not selected
    </span>
  </div>
</div>
