import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cp-rp-score-chart',
  templateUrl: './score-chart.component.html',
  styleUrls: ['./score-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreChartComponent {
  private _displayValue?: string | number;
  private _formattedDisplayValue?: string;

  @Input({ required: true })
  progress: number = 0;

  /**
   * Shows or hides chart value
   */
  @Input()
  showValue: boolean = true;

  /**
   * Shows the score progress by filling the area before the score
   */
  @Input()
  showProgress: boolean = true;

  @Input()
  targetFloor?: number;

  @Input()
  targetCeiling?: number;

  @Input()
  targetFloorDisplayValue?: number | string;

  @Input()
  targetCeilingDisplayValue?: number | string;

  /**
   * Tail label at the start of the chart
   */
  @Input()
  tailStart?: string;

  /**
   * Tail label at the end of the chart
   */
  @Input()
  tailEnd?: string;

  @Input()
  set displayValue(value: string | number | undefined) {
    this._displayValue = value;

    if (typeof value === 'string') {
      this._formattedDisplayValue = value.replaceAll(/[^\d-.]/g, '');
    } else if (typeof value === 'number') {
      this._formattedDisplayValue = value.toString(10);
    } else {
      this._formattedDisplayValue = value;
    }
  }

  get displayValue(): string | number | undefined {
    return this._displayValue;
  }

  get formattedDisplayValue(): string | undefined {
    return this._formattedDisplayValue;
  }
}
