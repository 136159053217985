export class Random {
  /**
   * Gets (pseudo) random ID
   * I wanted to give credit to SO person, but I forgot where I got it from
   * @param length ID length. Defaults to 6.
   */
  public static id(length: number = 6): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
}
