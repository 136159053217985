import { Component, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { TemplateService } from '../../services/template.service';
import { TagValues } from '../../models/report/tag-values';

interface TagItem {
  body: string;
  bucketId: string;
}

@Component({
  selector: 'cp-rp-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent extends PublicReportComponent implements OnInit {
  // This will move to a 'DebugService' - for now there is only this component to debug
  // and I need it quick so...
  protected debug: boolean = true;
  protected tagItems: TagItem[] = [];

  @Input()
  tag?: string;

  @Input()
  display: 'list' | 'paragraph' | 'inline' | 'auto' = 'auto';

  @Input()
  listStyle:
    | 'disc'
    | 'lower-alpha'
    | 'upper-alpha'
    | 'lower-roman'
    | 'upper-roman'
    | 'number' = 'disc';

  @Input()
  label?: string;

  @Input()
  maxItems: number = 100;

  constructor(private readonly _templateService: TemplateService) {
    super();
  }

  ngOnInit(): void {
    if (!this.tag) return;

    this.tagItems = this.getTagValuesForMeasure(this.tag);
  }

  private getTagValuesForMeasure(tag: string): TagItem[] {
    const measuresForTag = this.reportData.completed.tags[tag];
    const values: TagItem[] = [];

    if (!measuresForTag) return [];

    if (measuresForTag[this.measureId]) {
      const renderedValues = measuresForTag[this.measureId]
        .map(this.renderTagValues.bind(this))
        .flat()
        .slice(0, this.maxItems);

      values.push(...renderedValues);
    }

    // Get tags for supplemental measures if any...
    for (const supMeasure of this.component.supplementalMeasures) {
      if (!measuresForTag[supMeasure]) continue;

      const renderedValues = measuresForTag[supMeasure]
        .map(this.renderTagValues.bind(this))
        .flat()
        .slice(0, this.maxItems);

      values.push(...renderedValues);
    }

    return values;
  }

  private renderTagValues(tagValues: TagValues): TagItem[] {
    return tagValues.values.map(v => {
      return {
        body: this._templateService.render(v),
        bucketId: tagValues.bucketId,
      };
    });
  }
}
