import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ChartType } from '../../types/chart-type.type';
import { ElementSizeSync } from '@compass/ui';

/**
 * Components that shows chart scale with values
 */
@Component({
  selector: 'cp-rp-chart-scale',
  templateUrl: './chart-scale.component.html',
  styleUrls: ['./chart-scale.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartScaleComponent implements AfterViewChecked {
  /**
   * Whether to show the numerical values in the scale
   */
  @Input()
  showLabels: boolean = true;

  /**
   * Whether to show ticks for each value
   */
  @Input()
  showTicks: boolean = true;

  /**
   * Values for the scale
   */
  @Input({ required: true })
  chartValues: number[] = [];

  /**
   * Type of the scale
   */
  @Input()
  @HostBinding('class')
  scaleType: ChartType = 'continuous';

  @ViewChild('chartTable')
  chartTable?: ElementRef<HTMLElement>;

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
  ) {}

  ngAfterViewChecked(): void {
    this.updateHostHeight();
  }

  private updateHostHeight(): void {
    if (!this.chartTable) return;

    ElementSizeSync.sync(
      this._elementRef.nativeElement,
      this.chartTable.nativeElement,
      this._renderer,
    );
  }
}
