<h5 *ngIf="showMeasureCount && measureService.measures.length <= 2">
  1 measure
</h5>
<h5 *ngIf="showMeasureCount && measureService.measures.length > 2">
  {{ measureService.measures.length - 1 }} measures
</h5>
<div class="d-flex" *ngIf="renderedTemplate">
  <i class="fa-solid fa-circle-info fs-4 text-info me-2"></i>
  <div [innerHTML]="renderedTemplate" class="content"></div>
</div>
