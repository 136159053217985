import { HttpRequestMethod } from '../helpers';
import { RequestBuilderWithBody } from './request-builder-with-body';
import { HttpClient } from '@angular/common/http';

/**
 * Represents a request builder for making PUT requests.
 * @template TResult - The type of the response data.
 * @extends RequestBuilderWithBody
 */
export class PutRequestBuilder<
  TResult = unknown,
> extends RequestBuilderWithBody<TResult> {
  constructor(httpClient: HttpClient) {
    super(httpClient, HttpRequestMethod.Put);
  }
}
