import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Injection token for JWT token
 */
export const JWT_TOKEN_PROVIDER = new InjectionToken<() => string | undefined>(
  'compass-jwt-token',
);

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    @Optional()
    @Inject(JWT_TOKEN_PROVIDER)
    private readonly _jwtToken?: () => string | undefined,
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const token = this._jwtToken?.();

    if (!token) {
      return next.handle(req);
    }

    const authRequest = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });

    return next.handle(authRequest);
  }
}
