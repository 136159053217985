/**
 * Class for scheduling actions on a JS task queue
 */
export class TaskQueue {
  /**
   * Runs the callback function on the next loop of the macro queue.
   * @param callback Function to schedule for calling
   */
  static scheduleOnMacroQueue(callback: (...args: unknown[]) => void): void {
    setTimeout(callback, 0);
  }

  /**
   * Runs the callback function on the next loop of the micro queue.
   * @param callback Function to schedule for calling
   */
  static scheduleOnMicroQueue(callback: (...args: unknown[]) => void): void {
    Promise.resolve().then(callback);
  }
}
