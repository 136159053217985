/* eslint-disable @typescript-eslint/ban-ts-comment */

/**
 * Math renderer can create math renders for given elements.
 */
export class MathRenderer {
  private readonly READY_CHECK_INTERVAL_MS: number = 50;
  private readonly MAXIMUM_WAIT_TIME_MS: number = 3000;
  // @ts-ignore
  private _mathJax;

  /**
   * Renders provided element into math.
   * @param element Element to render as math
   * @param blockDisplay Whether the output should be inline or block display mode
   */
  async render(
    element: HTMLElement,
    blockDisplay: boolean = false,
  ): Promise<void> {
    if (!element) {
      throw new Error('Cannot render math in undefined HTML element.');
    }

    if (!(await this.waitReady())) {
      throw new Error(
        'The math renderer took too long to respond. Rendering terminated.',
      );
    }

    try {
      this.setElementDisplay(element, blockDisplay);
      await this._mathJax.typesetPromise([element]);
    } catch (error) {
      throw new Error(
        'Failed to render math element. Please check the math syntax.',
      );
    }
  }

  /**
   * Waits for MathJax to be ready (if it is not). It will try to wait until the
   * maximumWaitTime is reached.
   * @param maximumWaitTime Maximum time to wait
   * @private
   */
  private waitReady(
    maximumWaitTime: number = this.MAXIMUM_WAIT_TIME_MS,
  ): Promise<boolean> {
    // If the is ready flag is set then we can resolve right away...
    if (this.checkReady()) return Promise.resolve(true);

    // If not we have to wait some time...
    return new Promise<boolean>(resolve => {
      if (this.checkReady()) resolve(true);

      let elapsedMs = 0;
      const interval = setInterval(() => {
        elapsedMs += this.READY_CHECK_INTERVAL_MS;
        // If the timer expired then clear it and resolve to false
        if (elapsedMs >= maximumWaitTime) {
          clearInterval(interval);
          resolve(false);
        } else if (this.checkReady()) {
          clearInterval(interval);
          resolve(true);
        }
      }, this.READY_CHECK_INTERVAL_MS);
    });
  }

  /**
   * Checks whether MathJax has been defined in the window
   * @private
   */
  private checkReady(): boolean {
    if (this._mathJax) return true;

    // @ts-ignore
    const isReady = typeof MathJax.typeset === 'function';

    if (isReady) {
      // @ts-ignore
      this._mathJax = window.MathJax;
    }

    return isReady;
  }

  /**
   * Sets the inner HTML of an element to contain either inline or block
   * text syntax.
   * @param element
   * @param blockDisplay
   * @private
   */
  private setElementDisplay(element: HTMLElement, blockDisplay: boolean): void {
    if (blockDisplay) {
      element.innerHTML = `\\[${element.innerHTML}\\]`;
    } else {
      element.innerHTML = `\\(${element.innerHTML}\\)`;
    }
  }
}
