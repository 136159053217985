import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { PageService } from '../../services/page.service';
import { TaskQueue } from '@compass/helpers';
import { AccordionService } from '../../services/accordion.service';

@Component({
  selector: 'cp-rp-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent extends PublicReportComponent implements OnInit {
  @Input()
  name: string = '';

  @Input()
  open: boolean = false;

  get pageId(): string {
    return this.component.id;
  }

  constructor(
    private readonly _pageService: PageService,
    private readonly _elementRef: ElementRef,
    private readonly _accordion: AccordionService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.component.isTopComponent) return;

    TaskQueue.scheduleOnMacroQueue(() => {
      this._pageService.registerPage(this);
    });
  }

  focusMeasure(measureId: string): void {
    setTimeout(() => {
      const element = this._elementRef.nativeElement?.querySelector(
        `[data-focusable="${measureId}"]`,
      );

      if (!element) return;

      // Collapse other accordions and expand the focused one
      this._accordion.collapseAll();
      this._accordion.expand(measureId);

      // Scroll the element into view
      element.scrollIntoView();
    }, 400);
  }

  protected isActive(): boolean {
    return this._pageService.activePage?.pageId === this.pageId;
  }
}
