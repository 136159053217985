import { Component, Input } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { VideoStatus } from '@compass/video';

@Component({
  selector: 'cp-rp-report-vimeo-player',
  template: `
    <cp-vimeo
      [src]="src"
      [showCaptions]="showCaptions"
      (stateChanged)="videoStateChanged($event)" />

    <p *ngIf="vimeoPlayerError" class="pt-2 fst-italic text-danger">
      An error occurred and the video could not be loaded.
    </p>
  `,
})
export class ReportVimeoPlayerComponent extends PublicReportComponent {
  @Input() src: string = '';

  @Input() showCaptions: boolean = true;

  protected vimeoPlayerError: boolean = false;

  videoStateChanged(videoStatus: VideoStatus): void {
    if (videoStatus.failedToLoad) this.vimeoPlayerError = true;
  }
}
