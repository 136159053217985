<table *ngIf="showTicks || showLabels" #chartTable>
  <tr
    [cpSizeSync]="contentWrapper"
    [syncEnabled]="contentWrapper.childElementCount > 0">
    <ng-container *ngIf="scaleType === 'continuous'">
      <td
        [class.scale-cell]="showTicks"
        *ngFor="let value of [].constructor(chartValues.length - 1)">
        &nbsp;
      </td>
    </ng-container>
    <ng-container *ngIf="scaleType === 'discrete'">
      <td [class.scale-cell]="showTicks" *ngFor="let value of chartValues">
        &nbsp;
      </td>
    </ng-container>
  </tr>
  <tr *ngIf="showLabels" class="h-0">
    <ng-container *ngIf="scaleType === 'continuous'">
      <td
        class="value-cell pt-1"
        *ngFor="
          let value of [].constructor(chartValues.length - 1);
          let i = index;
          let isLast = last
        ">
        <div class="d-flex justify-content-between">
          <ng-container
            *ngTemplateOutlet="
              valueCell;
              context: { $implicit: chartValues[i] }
            " />
          <ng-container
            *ngIf="isLast"
            [ngTemplateOutlet]="valueCell"
            [ngTemplateOutletContext]="{ $implicit: chartValues[i + 1] }" />
        </div>
      </td>
    </ng-container>
    <ng-container *ngIf="scaleType === 'discrete'">
      <td class="text-center value-cell" *ngFor="let value of chartValues">
        <ng-container
          *ngTemplateOutlet="valueCell; context: { $implicit: value }" />
      </td>
    </ng-container>
  </tr>
</table>

<div #contentWrapper>
  <ng-content />
</div>

<ng-template #valueCell let-value>
  <span>
    {{ value | number: '1.0-1' }}
  </span>
</ng-template>
