import { Renderer2 } from '@angular/core';
import { TaskQueue } from '@compass/helpers';

/**
 * Functions for synchronizing size between two {@link HTMLElement} instances
 */
export class ElementSizeSync {
  /**
   * Synchronizes size between source and target element
   * @param source Element as the source of the size values
   * @param target Element to be set to the same size as source
   * @param renderer Renderer to use
   */
  public static sync(
    target: HTMLElement,
    source: HTMLElement,
    renderer?: Renderer2,
  ): void {
    TaskQueue.scheduleOnMacroQueue(() => {
      const sourceSize = source.getBoundingClientRect();

      if (renderer) {
        renderer.setStyle(target, 'height', sourceSize.height + 'px');
      } else {
        target.style.height = sourceSize.height + 'px';
      }
    });
  }
}
