import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { defaultScalingOptions, ScalingOptions } from './scaling-options';

/**
 * Service that proportionally scales target elements to a source element
 */
@Injectable()
export class ScaleService {
  private readonly _renderer: Renderer2;

  private _source?: Element;
  private _scalingOptions: ScalingOptions = defaultScalingOptions();
  private _targets: Element[] = [];

  constructor(rendererFactory: RendererFactory2) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Sets a scaling source with options
   * @param nativeElement Source element
   * @param scalingOptions Options for scaling
   */
  setSource(
    nativeElement: Element | null,
    scalingOptions?: Partial<ScalingOptions>,
  ): void {
    if (!nativeElement) {
      this.reset();
      return;
    }

    this._scalingOptions = {
      ...defaultScalingOptions(),
      ...scalingOptions,
    };
    this._source = nativeElement;

    setTimeout(() => this.doScale(this._targets));
  }

  /**
   * Adds a target for scaling
   * @param target Target element to scale
   */
  addTarget(target: Element): void {
    if (this.contains(target)) return;

    this._targets.push(target);
    setTimeout(() => this.doScale([target]));
  }

  /**
   * Removes a target from scaling
   * @param target Target element to remove
   */
  removeTarget(target: Element): void {
    const index = this._targets.indexOf(target);

    if (index === -1) return;

    this._targets.splice(index, 1);
  }

  private contains(target: Element): boolean {
    return this._targets.indexOf(target) >= 0;
  }

  private doScale(elements: Element[]): void {
    if (!this._source || elements.length === 0) return;

    const sourceSize = this._source.getBoundingClientRect();

    for (const element of elements) {
      const scaleElement = this.getScalingElementBySelector(
        element,
        this._scalingOptions.targetSelector,
      );

      this.scaleElement(
        scaleElement,
        sourceSize,
        this._scalingOptions.scalingFactor,
      );
    }
  }

  private getScalingElementBySelector(
    element: Element,
    targetSelector?: string,
  ): Element {
    if (!targetSelector) {
      return element;
    }

    const selectedElement = element.querySelector(targetSelector);

    return selectedElement ?? element;
  }

  private scaleElementWrapper(element: Element): void {
    const scaleWrapper = this.getScaleWrapper(element);
    const scaleSize = element.getBoundingClientRect();

    this._renderer.setStyle(scaleWrapper, 'width', scaleSize.width + 'px');
    this._renderer.setStyle(scaleWrapper, 'height', scaleSize.height + 'px');
  }

  private scaleElement(
    element: Element,
    sourceSize: { width: number; height: number },
    scalingFactor: number,
  ): void {
    // Clean the style first - if there is any
    this._renderer.removeStyle(element, 'scale');

    const scaleElementSize = element.getBoundingClientRect();
    const targetScaleFactor =
      this._scalingOptions.orientation === 'horizontal'
        ? (sourceSize.width * scalingFactor) / scaleElementSize.width
        : (sourceSize.height * scalingFactor) / scaleElementSize.height;

    this._renderer.setStyle(element, 'scale', targetScaleFactor);
    this._renderer.setStyle(element, 'transform-origin', '0 0');

    this.scaleElementWrapper(element);
  }

  private getScaleWrapper(toWrap: Element): HTMLElement {
    if (
      !(toWrap.parentNode instanceof HTMLElement) ||
      toWrap.parentNode.hasAttribute('data-scale-wrapper')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return toWrap.parentElement!;
    }

    const wrapper = this._renderer.createElement('div');

    this._renderer.setAttribute(wrapper, 'data-scale-wrapper', '');

    this._renderer.insertBefore(
      this._renderer.parentNode(toWrap),
      wrapper,
      toWrap,
    );
    this._renderer.appendChild(wrapper, toWrap);

    return wrapper;
  }

  private reset(): void {
    this._source = undefined;
    this._scalingOptions = defaultScalingOptions();
    this._targets = [];
  }
}
