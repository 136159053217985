import { HttpClient } from '@angular/common/http';
import { SuccessApiResponse, ErrorApiResponse } from '../responses';
import {
  HttpRequest,
  HttpRequestMethod,
  HttpRequestOptions,
  HttpResponseType,
} from '../helpers';

/**
 * Object for fluent building of a request
 */
export class RequestBuilder<TResult = unknown> {
  private _route: string = '';
  private _routeSegments?: (string | number)[];
  private _responseType: HttpResponseType = HttpResponseType.Json;

  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly method: HttpRequestMethod,
  ) {}

  /**
   * Adds a route to a request
   * @param route Route to add
   */
  addRoute(route: string): this {
    this._route += route;

    return this;
  }

  /**
   * Adds route segments to request
   * @param segments Segments to add
   */
  addRouteSegments(...segments: (string | number)[]): this {
    this._routeSegments = segments;

    return this;
  }

  /**
   * Sets a response type to expect from the request
   * @param responseType Response type
   */
  withResponseType(responseType: HttpResponseType): this {
    this._responseType = responseType;

    return this;
  }

  /**
   * Builds an HttpRequest<TResult> object with the provided properties.
   *
   * @return {HttpRequest<TResult>} The newly created HttpRequest object.
   */
  build(): HttpRequest<TResult> {
    const route = this.getRemoteUrl();

    return new HttpRequest<TResult>(
      this.httpClient,
      route,
      this.getHttpRequestOptions(),
    );
  }

  /**
   * Sends the request and returns a promise that resolves to an ApiResponse.
   *
   * @returns {Promise<SuccessApiResponse<TResult>>} A promise that resolves to an ApiResponse if request succeeds or ErrorResponse if request fails.
   */
  send(): Promise<SuccessApiResponse<TResult> | ErrorApiResponse> {
    return this.build().send();
  }

  protected getHttpRequestOptions(): Partial<HttpRequestOptions> {
    return {
      method: this.method,
      responseType: this._responseType,
    };
  }

  /**
   * Gets the URL for the remote endpoint.
   * @protected
   */
  protected getRemoteUrl(): string {
    let url = this._route;

    if (this._routeSegments) {
      url += this.getSegmentsString(...this._routeSegments);
    }

    return url;
  }

  private getSegmentsString(...segments: (string | number)[]): string {
    let url = '';

    if (segments && segments.length > 0) {
      for (const segment of segments) {
        url += `/${segment}`;
      }
    }

    return url;
  }
}
