import { NgModule } from '@angular/core';
import { ScaleSourceDirective } from './scale-source.directive';
import { ScaleTargetDirective } from './scale-target.directive';
import { ScaleService } from './scale.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ScaleSourceDirective, ScaleTargetDirective],
  imports: [CommonModule],
  exports: [ScaleSourceDirective, ScaleTargetDirective],
  providers: [ScaleService],
})
export class ScaleModule {}
