<ng-container *ngIf="chartConfig && score">
  <cp-rp-chart-scale
    [chartValues]="chartConfig.valueLabels"
    [showLabels]="showRangeValues"
    [showTicks]="showRangeTicks"
    [scaleType]="chartConfig.chartType">
    <cp-rp-score-chart
      [showProgress]="chartConfig.chartType === 'continuous'"
      [progress]="chartConfig.progress"
      [displayValue]="score.formattedScore"
      [targetFloor]="chartConfig.targetFloor"
      [targetCeiling]="chartConfig.targetCeiling"
      [targetFloorDisplayValue]="target?.floorFormatted"
      [targetCeilingDisplayValue]="target?.ceilingFormatted"
      [tailEnd]="tailEnd"
      [tailStart]="tailStart"
      [showValue]="showValue" />
  </cp-rp-chart-scale>
</ng-container>
