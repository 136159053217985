import { Component, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { Measure } from '../../models/report/measure';
import { Question } from '../../models/report/question';
import { ParticipantSelection } from '../../models/report/participant-selection';
import { isCorrectAnswer } from '../../helpers/participant-selection-helpers';
import { ROOT_MEASURE_ID } from '../../constants/measures';

interface AnswerItem {
  measure: Measure;
  questions: {
    question: Question;
    selection?: ParticipantSelection;
  }[];
  totalScoredQuestions: number;
  totalCorrectQuestions: number;
}

@Component({
  selector: 'cp-rp-answers',
  templateUrl: './answers.component.html',
})
export class AnswersComponent extends PublicReportComponent implements OnInit {
  protected readonly ROOT_MEASURE_ID = ROOT_MEASURE_ID;

  protected items: AnswerItem[] = [];

  @Input()
  collapsed: boolean = false;

  ngOnInit(): void {
    const measuresToGet = this.component.explicitMeasure()
      ? [this.measure.measureId]
      : this.measureService.measures.map(m => m.measureId);

    for (const measureId of measuresToGet) {
      const items = this.getItemsForMeasure(measureId);

      if (!items || items.questions.length === 0) continue;

      this.items.push(items);
    }
  }

  private getItemsForMeasure(measureId: string): AnswerItem | undefined {
    const measure = this.measureService.getMeasureById(measureId);

    if (!measure) return undefined;

    const questions = this.reportData.questions
      .filter(q => q.measureIds.includes(measureId))
      .map(q => {
        return {
          question: q,
          selection: this.reportData.completed.selections.find(
            s => s.questionId === q.questionId,
          ),
        };
      });

    return {
      measure,
      questions,
      totalCorrectQuestions: questions.filter(
        q => q.selection && isCorrectAnswer(q.selection),
      ).length,
      totalScoredQuestions: questions.filter(q => q.question.isScored).length,
    };
  }
}
