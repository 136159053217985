import { Injectable } from '@angular/core';
import { Score } from '../models/report/score';
import { ScorePresentationType } from '../models/report/score-presentation-type';
import { ScoringBucket } from '../models/report/scoring-bucket';
import { ReportService } from './report.service';
import { MeasureService } from './measure.service';
import { Logger } from '@compass/logging';
import { arrayFromRange } from '@compass/helpers';
import { ChartType } from '../types/chart-type.type';
import { Measure } from '../models/report/measure';

/**
 * Chart configuration values
 */
export interface ChartConfig {
  /**
   * Percent progress of the chart from 0 to 1
   */
  progress: number;
  targetFloor?: number;
  targetCeiling?: number;
  minValue: number;
  maxValue: number;
  valueLabels: number[];
  chartType: ChartType;
}

const emptyChartValue: ChartConfig = {
  progress: 0,
  minValue: 0,
  maxValue: 1,
  valueLabels: [0, 1],
  chartType: 'continuous',
};

/**
 * Provider for chart configuration values
 */
@Injectable({
  providedIn: 'root',
})
export class ChartConfigProvider {
  constructor(
    private readonly _reportService: ReportService,
    private readonly _measureService: MeasureService,
    private readonly _logger: Logger,
  ) {}

  getConfig(scoreOrMeasureId: Score | string): ChartConfig {
    return typeof scoreOrMeasureId === 'string'
      ? this.getConfigByMeasureId(scoreOrMeasureId)
      : this.getConfigByScore(scoreOrMeasureId);
  }

  private getConfigByMeasureId(measureId: string): ChartConfig {
    const score = this._reportService.reportData?.completed?.scores?.find(
      s => s.measureId === measureId,
    );

    if (!score) {
      this._logger.error(
        `Failed to get generate chart values for measure [${measureId}] because score for it was not found.`,
      );
      return emptyChartValue;
    }

    return this.getConfigByScore(score);
  }

  private getConfigByScore(score: Score): ChartConfig {
    const measure = this._measureService.getMeasureById(score.measureId);

    if (!measure) {
      this._logger.error(
        `Failed to get generate chart values for measure [${score.measureId}] because measure was not found.`,
      );
      return emptyChartValue;
    }

    const { min, max } = this.getChartMinMaxValues(
      measure.measureId,
      measure.scorePresentationType,
      score.score,
    );
    const target = this.getChartTarget(measure, min, max);

    return {
      progress: this.getChartProgress(
        measure.scorePresentationType,
        score.score,
        min,
        max,
      ),
      targetFloor: target.floor,
      targetCeiling: target.ceiling,
      minValue: min,
      maxValue: max,
      valueLabels: this.getValueLabels(measure.scorePresentationType, min, max),
      chartType: this.getChartType(measure.scorePresentationType),
    };
  }

  private getChartType(
    scorePresentationType: ScorePresentationType,
  ): ChartType {
    switch (scorePresentationType) {
      case ScorePresentationType.Sten:
      case ScorePresentationType.Stanine:
        return 'discrete';
      default:
        return 'continuous';
    }
  }

  private getValueLabels(
    scorePresentationType: ScorePresentationType,
    min: number,
    max: number,
  ): number[] {
    switch (scorePresentationType) {
      case ScorePresentationType.PercentOfPoints:
      case ScorePresentationType.Rank:
        return arrayFromRange(0, 100, 25);
      case ScorePresentationType.ZScore:
        return arrayFromRange(min, max, 0.5, 10);
      default:
        return arrayFromRange(min, max, 1, 10);
    }
  }

  private getChartMinMaxValues(
    measureId: string,
    scorePresentationType: ScorePresentationType,
    score: number,
  ): { min: number; max: number } {
    switch (scorePresentationType) {
      case ScorePresentationType.Stanine:
        return { min: 1, max: 9 };
      case ScorePresentationType.Sten:
        return { min: 1, max: 10 };
      case ScorePresentationType.SumOfPoints:
        return {
          min: this.getMinValueFromBuckets(measureId, score),
          max: this.getMaxValueFromBuckets(measureId, score),
        };
      case ScorePresentationType.ZScore:
        return this.getZScoreMinMaxValues(measureId, score);
    }

    return { min: 0, max: 1 };
  }

  private getChartProgress(
    scorePresentationType: ScorePresentationType,
    value: number,
    min: number,
    max: number,
  ): number {
    let progress = 0;

    switch (scorePresentationType) {
      case ScorePresentationType.Stanine:
        progress = (2 * Math.round(value) - 1) / (2 * 9);
        break;
      case ScorePresentationType.Sten:
        progress = (2 * Math.round(value) - 1) / (2 * 10);
        break;
      default:
        progress = (value - min) / (max - min);
    }

    if (progress < 0) return 0;
    if (progress > 1) return 1;

    return progress;
  }

  private getChartTarget(
    measure: Measure,
    min: number,
    max: number,
  ): {
    floor?: number;
    ceiling?: number;
  } {
    const targetBucket = this._reportService.reportData?.scoring?.targets?.find(
      t => t.measureId === measure.measureId,
    );

    if (!targetBucket) {
      return { floor: undefined, ceiling: undefined };
    }

    switch (measure.scorePresentationType) {
      case ScorePresentationType.Stanine:
        return {
          floor: (Math.round(targetBucket.floor) - 1) / 9,
          ceiling: Math.round(targetBucket.ceiling) / 9,
        };
      case ScorePresentationType.Sten:
        return {
          floor: (Math.round(targetBucket.floor) - 1) / 10,
          ceiling: Math.round(targetBucket.ceiling) / 10,
        };
      default:
        return {
          floor: this.getChartProgress(
            measure.scorePresentationType,
            targetBucket.floor - 0.05,
            min,
            max,
          ),
          ceiling: this.getChartProgress(
            measure.scorePresentationType,
            targetBucket.ceiling + 0.05,
            min,
            max,
          ),
        };
    }
  }

  private getZScoreMinMaxValues(
    measureId: string,
    score: number,
  ): {
    min: number;
    max: number;
  } {
    const bucketMin = Math.abs(this.getMinValueFromBuckets(measureId, score));
    const bucketMax = Math.abs(this.getMaxValueFromBuckets(measureId, score));
    const limit =
      Math.ceil((bucketMin > bucketMax ? bucketMin : bucketMax) * 2) / 2;

    return { min: limit * -1, max: limit };
  }

  private getMinValueFromBuckets(measureId: string, score: number): number {
    let minValue = Number.MAX_VALUE;

    for (const bucket of this.getBucketsForMeasure(measureId)) {
      if (bucket.floor >= minValue) continue;

      minValue = bucket.floor;
    }

    return score < minValue ? score : minValue;
  }

  private getMaxValueFromBuckets(measureId: string, score: number): number {
    let maxValue = Number.MIN_VALUE;

    for (const bucket of this.getBucketsForMeasure(measureId)) {
      if (bucket.ceiling <= maxValue) continue;

      maxValue = bucket.ceiling;
    }

    return score > maxValue ? score : maxValue;
  }

  private getBucketsForMeasure(measureId: string): ScoringBucket[] {
    if (!this._reportService.hasReport) return [];

    return [
      ...this._reportService.reportData!.scoring.targets.filter(
        t => t.measureId === measureId,
      ),
      ...this._reportService.reportData!.scoring.scoringBuckets.filter(
        sb => sb.measureId === measureId,
      ),
    ];
  }
}
