import { BaseResponse } from './base-response';
import { ResponseError } from './reponse-error';

/**
 * API error response
 */
export interface ErrorApiResponse extends BaseResponse {
  /**
   * False for successful response.
   */
  isSuccess: false;

  /**
   * Errors that occurred during the request
   */
  errors?: ResponseError[];
}

/**
 * Checks whether specified error is custom HTTP error
 * @param error Error to check
 */
export function isCustomHttpErrorResponse(
  error?: unknown,
): error is ErrorApiResponse {
  if (!error || typeof error !== 'object') return false;

  const errorObj = error as ErrorApiResponse;

  return errorObj.isSuccess !== undefined && !errorObj.isSuccess;
}

/**
 * Checks whether specified error is custom HTTP error with at least one error message
 * @param error Error to check
 */
export function isDetailedCustomHttpErrorResponse(
  error?: unknown,
): error is ErrorApiResponse {
  return (
    isCustomHttpErrorResponse(error) &&
    error.errors !== undefined &&
    error.errors.length > 1
  );
}
