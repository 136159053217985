import { LoggingProvider } from './providers/logging-provider';
import { LogLevel } from './options/log-level';

/**
 * Logs items to different providers
 */
export class Logger {
  private static _instance?: Logger;

  public static get instance(): Logger {
    if (Logger._instance === undefined) {
      throw new Error(
        'An attempt was made to log from static context, however, ' +
          'the logger has not been initialized. ' +
          'Make sure you are importing LoggingModule into your module.',
      );
    }

    return Logger._instance;
  }

  constructor(
    private readonly _providers: LoggingProvider[],
    private readonly _minLogLevel: LogLevel,
  ) {
    Logger._instance = this;
  }

  /**
   * Gets specified logging provider if it exists
   * @param t Type of provider to get
   * @returns Provider if found or undefined
   */
  getProvider<T extends LoggingProvider>(
    t: new (...args: never[]) => T,
  ): LoggingProvider | undefined {
    return this._providers.find(provider => provider instanceof t);
  }

  /**
   * Logs items with info severity
   * @param items Items to log
   */
  info(...items: unknown[]): void {
    if (!this.meetsMinLogLevelRequirement('info')) return;

    this.logToProviders('info', items);
  }

  /**
   * Logs items with warning severity
   * @param items Items to log
   */
  warn(...items: unknown[]): void {
    if (!this.meetsMinLogLevelRequirement('warn')) return;

    this.logToProviders('warn', items);
  }

  /**
   * Logs items with ERROR severity
   * @param items Items to log
   */
  error(...items: unknown[]): void {
    if (!this.meetsMinLogLevelRequirement('error')) return;

    this.logToProviders('error', items);
  }

  /**
   * Logs items with debug severity
   * @param items Items to log
   */
  debug(...items: unknown[]): void {
    if (!this.meetsMinLogLevelRequirement('debug')) return;

    this.logToProviders('debug', items);
  }

  private logToProviders(level: LogLevel, items: unknown | unknown[]): void {
    for (const provider of this._providers) {
      try {
        this.doLog(items, this.getLogFc(level, provider).bind(provider));
      } catch (error) {
        console.error(
          `Failed to log ${level} for provider '${provider.name}'.`,
          error,
        );
      }
    }
  }

  private getLogFc(
    level: LogLevel,
    provider: LoggingProvider,
  ): (...args: unknown[]) => void {
    switch (level) {
      case 'debug':
        return provider.debug;
      case 'info':
        return provider.info;
      case 'error':
        return provider.error;
      case 'warn':
        return provider.warn;
    }
  }

  private doLog(
    items: unknown | unknown[],
    logFc: (...args: unknown[]) => void,
  ): void {
    if (Array.isArray(items)) {
      logFc(...items);
    } else {
      logFc(items);
    }
  }

  private meetsMinLogLevelRequirement(level: LogLevel): boolean {
    if (this._minLogLevel === 'error') {
      return level === 'error';
    }

    if (this._minLogLevel === 'warn') {
      return level === 'error' || level === 'warn';
    }

    if (this._minLogLevel === 'info') {
      return level === 'error' || level === 'info' || level === 'warn';
    }

    return true;
  }
}
