import { Component } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'cp-rp-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss'],
})
export class PageMenuComponent extends PublicReportComponent {
  constructor(protected readonly pageService: PageService) {
    super();
  }
}
