import { Component, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { ScoringBucket } from '../../models/report/scoring-bucket';
import { Breakpoints } from '@compass/environment';
import { PageService } from '../../services/page.service';
import {
  ChartConfig,
  ChartConfigProvider,
} from '../../services/chart-config-provider.service';

@Component({
  selector: 'cp-rp-label-chart',
  templateUrl: './label-chart.component.html',
  styleUrls: ['./label-chart.component.scss'],
})
export class LabelChartComponent
  extends PublicReportComponent
  implements OnInit
{
  protected scoringBucketForScore?: ScoringBucket;
  protected chartConfig!: ChartConfig;

  @Input()
  orientation: 'vertical' | 'horizontal' = 'vertical';

  @Input()
  goToPage?: string;

  @Input()
  showLabel: boolean = false;

  constructor(
    protected readonly breakpoints: Breakpoints,
    protected readonly pageService: PageService,
    private readonly _chartConfigProvider: ChartConfigProvider,
  ) {
    super();
  }

  ngOnInit(): void {
    this.scoringBucketForScore = this.scoringBuckets.find(
      sb => sb.scoringBucketId === this.score.scoringBucketId,
    );

    this.chartConfig = this._chartConfigProvider.getConfig(this.score);
  }
}
