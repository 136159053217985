/**
 * Returns a Record object containing CSS classes based on the given items.
 *
 * @param {...(string | {
 *     cssClass?: string;
 *     prefix?: string;
 *     condition?: boolean | (() => boolean);
 * })[]} items - A string of CSS classes separated by space or an object of those classes with options
 * @returns {Record<string, boolean>} - The Record object containing CSS classes.
 */
export function getCssClasses(
  ...items: (
    | string
    | {
        cssClass?: string;
        prefix?: string;
        condition?: boolean | (() => boolean);
      }
  )[]
): Record<string, boolean> {
  let classObject: Record<string, boolean> = {};

  for (const item of items) {
    const newItems =
      typeof item === 'string'
        ? getClassObject(item)
        : getClassObject(item.cssClass, item.prefix, item.condition);

    classObject = {
      ...classObject,
      ...newItems,
    };
  }

  return classObject;
}

function getClassObject(
  cssClass?: string,
  prefix?: string,
  condition?: boolean | (() => boolean),
): Record<string, boolean> {
  const result: Record<string, boolean> = {};

  if (!cssClass) return result;

  for (const item of cssClass.split(' ')) {
    if (!item) continue;

    result[getFullyQualifiedName(item, prefix)] = shouldApply(condition);
  }

  return result;
}

function shouldApply(condition?: boolean | (() => boolean)): boolean {
  if (condition === undefined) {
    return true;
  }

  if (typeof condition === 'function') {
    return condition();
  }

  return condition;
}

function getFullyQualifiedName(cssClass: string, prefix?: string): string {
  return prefix ? `${prefix}-${cssClass}` : cssClass;
}
